const fieldsSectionOne = [
  [{
    label: 'Nazwa projektu',
  },
  {
    label: 'Projekt KR',
    type: 'select',
    data: [{ id: 1, value: 'mock', label: 'Item mock' }],
  },
  {
    label: 'Koordynator',
    type: 'select',
    data: [{ id: 1, value: 'mock', label: 'Item mock' }],
  },
  {
    label: 'Firma zgłaszająca projekt',
    type: 'select',
    data: [{ id: 1, value: 'mock', label: 'Item mock' }],
  },
  {
    label: 'Dystrybutor',
    type: 'select',
    data: [{ id: 1, value: 'mock', label: 'Item mock' }],
  },
  {
    label: 'Projektant',
    type: 'select',
    data: [{ id: 1, value: 'mock', label: 'Item mock' }],
  },
  {
    label: 'Architekt',
    type: 'select',
    data: [{ id: 1, value: 'mock', label: 'Item mock' }],
  },
  {
    label: 'Audytor',
    type: 'select',
    data: [{ id: 1, value: 'mock', label: 'Item mock' }],
  },],
  [{
    label: 'Status',
    type: 'select',
    data: [{ id: 1, value: 'mock', label: 'Item mock' }],
  },
  {
    label: 'Pompy',
  },
  {
    label: 'Kotły',
  },
  {
    label: 'Wytwornice',
  },
  {
    label: 'Suma',
  },
  {
    label: 'Wykonawca',
    type: 'select',
    data: [{ id: 1, value: 'mock', label: 'Item mock' }],
  },
  {
    label: 'Generalny wykonawca',
    type: 'select',
    data: [{ id: 1, value: 'mock', label: 'Item mock' }],
  },
  {
    label: 'Inwestor',
    type: 'select',
    data: [{ id: 1, value: 'mock', label: 'Item mock' }],
  },
  {
    label: 'Zamawiający',
    type: 'select',
    data: [{ id: 1, value: 'mock', label: 'Item mock' }],
  },
  ]
];

const fieldsSectionTwo = [
  {
    label: 'Udziałowcy',
    type: 'select',
    data: [{ id: 1, value: 'mock', label: 'Item mock' }],
    unit: 'percentage'
  },
]

const fieldsSectionThree = [
  { label: 'Nazwa robocza' },
  {
    label: 'Województwo',
    type: 'select',
    data: [{ id: 1, value: 'mock', label: 'Item mock' }],
  },
  { label: 'Miejscowość' },
  { label: 'Ulica' },
]

export { fieldsSectionOne, fieldsSectionTwo, fieldsSectionThree };