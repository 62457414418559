import React from 'react';
import ListToolBar from '../../../components/screen-components/marketing/list/ListToolBar';
import { headCells, rows } from '../../../mocks/marketingCompaniesTableMock';
import BasicTable from '../../../components/common/tables/basic/BasicTable';
import routerHistory from '../../../settings/routerHistory';

const MarketingCompaniesScreen = () => (
  <>
    <ListToolBar
      buttonText="Dodaj"
      onClick={() => routerHistory.push('/marketing/companies/add')}
    />
    <BasicTable headCells={headCells} rows={rows} />
  </>
);

export default MarketingCompaniesScreen;
