import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core';
import RowWrapper from '../../common/wrappers/RowWrapper';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background: #fff;
  padding: 25px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 50px;
`;

const ExpansionContainer = ({ children, title }) => {
  return (
    <>
      <Wrapper>
        <ExpansionPanel defaultExpanded style={{ width: '100%' }}>
          <ExpansionPanelSummary aria-controls="panel1a-content" id="panel1a-header">
            <RowWrapper>
              <ExpandMoreIcon />
              <Typography style={{ marginLeft: '10px', fontWeight: 'bold' }} color="secondary">
                {title}
              </Typography>
            </RowWrapper>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
        </ExpansionPanel>
      </Wrapper>
    </>
  );
};

ExpansionContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  title: PropTypes.string.isRequired,
};

export default ExpansionContainer;
