import React from 'react';
import ColumnWrapper from '../../components/common/wrappers/ColumnWrapper';
import FloatingSaveBar from '../../components/screen-components/shared/FloatingSaveBar';
import ServiceToolBar from '../../components/screen-components/projects/service/tool-bar/ServiceToolBar';
import Installation from '../../components/screen-components/projects/service/installation/Installation';
import Warranty from '../../components/screen-components/projects/service/warranty/Warranty';
import Devices from '../../components/screen-components/projects/service/devices/Devices';
import Notes from '../../components/screen-components/projects/service/notes/Notes';
import Offer from '../../components/screen-components/projects/service/offer/Offer';
import Cost from '../../components/screen-components/projects/service/cost/Cost';

const ServiceScreen = () => {
  return (
    <>
      <ColumnWrapper>
        <ServiceToolBar />
        <Installation />
        <Warranty />
        <Devices />
        <Notes />
        <Cost />
        <Offer />
      </ColumnWrapper>
      <FloatingSaveBar />
    </>
  );
};

export default ServiceScreen;
