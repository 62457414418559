const sectionOne = [
  {
    label: 'Oferta automatyki', type: 'button', options: {
      text: 'Plik PDF'
    }
  },
  { label: 'Gwarancja' },
  { label: 'Data upływu gwarancji' },
];

const sectionTwo = [
  { label: 'Wartość zamówienia' },
  { label: 'Data zamówienia' },
  { label: 'Czas realizacji' },
  { label: 'Ostateczny termin dostawy' },
];

export { sectionOne, sectionTwo };