import React, { Fragment } from 'react';
import { sectionTwo } from '../fields';
import { FormWrapper, InputLabel } from '../../../shared/FormComponents';
import { Checkbox, TextField, Typography } from '@material-ui/core';
import RowWrapper from '../../../../common/wrappers/RowWrapper';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';

const Form = () => (
  <FormWrapper>
    {sectionTwo.map((item, i) => (
      <Fragment key={`item${i}`}>
        <RowWrapper>
          <Checkbox />
          <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
            {item.label}
          </InputLabel>
          {!item.fields && item.input ? <TextField variant="filled" /> : null}
        </RowWrapper>
        {item.fields ? (
          <ColumnWrapper>
            {item.fields.map((field, index) => (
              <RowWrapper style={{ marginLeft: '15px' }} key={`item${index}`}>
                <InputLabel variant="body1">{field.label}</InputLabel>
                <TextField style={{ margin: '10px 20px' }} variant="filled" />
                {field.secondLabel ? (
                  <Typography variant="body2">{field.secondLabel}</Typography>
                ) : null}
              </RowWrapper>
            ))}
          </ColumnWrapper>
        ) : null}
      </Fragment>
    ))}
  </FormWrapper>
);

export default Form;
