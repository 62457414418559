import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { useParams } from 'react-router';
import SettingsIcon from '@material-ui/icons/Settings';
import BuildIcon from '@material-ui/icons/Build';
import styled from 'styled-components';
import RowWrapper from '../../../common/wrappers/RowWrapper';
import BackButton from '../../../common/buttons/back-button/BackButton';
import ColumnWrapper from '../../../common/wrappers/ColumnWrapper';
import routerHistory from '../../../../settings/routerHistory';
import ActiveStatus from '../list/ActiveStatus';

const Wrapper = styled(RowWrapper)`
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

const DetailsToolBar = () => {
  const { id } = useParams();

  return (
    <>
      <Wrapper>
        <RowWrapper>
          <BackButton text="Wróć do listy" onClick={() => routerHistory.push('/projects')} />
          <ColumnWrapper>
            <Typography>Projekt: {id}</Typography>
            <ActiveStatus />
          </ColumnWrapper>
        </RowWrapper>
        <RowWrapper>
          <ButtonWrapper>
            <Button
              startIcon={<SettingsIcon />}
              style={{ color: 'white' }}
              variant="contained"
              onClick={() => routerHistory.push(`/projects/${id}/automation`)}
              color="secondary">
              Automatyka
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              startIcon={<BuildIcon />}
              style={{ color: 'white' }}
              variant="contained"
              onClick={() => routerHistory.push(`/projects/${id}/service`)}
              color="secondary">
              Serwis
            </Button>
          </ButtonWrapper>
        </RowWrapper>
      </Wrapper>
    </>
  );
};

export default DetailsToolBar;
