import React from 'react';
import { useParams } from 'react-router';
import { categoriesRows, categoriesHeadCells } from '../../mocks/productsTableMock';
import routerHistory from '../../settings/routerHistory';
import BasicTable from '../../components/common/tables/basic/BasicTable';
import ListToolBar from '../../components/screen-components/products/list/ListToolBar';

const ProductsCategoryScreen = () => {
  const companyId = useParams().companyId;

  return (
    <>
      <ListToolBar type="categories" />
      <BasicTable
        headCells={categoriesHeadCells}
        rows={categoriesRows}
        onRowClick={({ id }) => routerHistory.push(`/products/${companyId}/categories/${id}`)}
      />
    </>
  );
};

export default ProductsCategoryScreen;
