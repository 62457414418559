import React from 'react';
import { rows, headCells } from './tableData';
import ExpansionContainer from '../../../../common/containers/ExpansionContainer';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import BasicTable from '../../../../common/tables/basic/BasicTable';
import PrimaryButton from '../../../../common/buttons/PrimaryButton';
import SpaceContainer from '../../../../common/containers/SpaceContainer';
import RowWrapper from '../../../../common/wrappers/RowWrapper';

const Notes = () => {
  return (
    <>
      <ExpansionContainer title="Notatki serwisowe">
        <ColumnWrapper fullWidth>
          <BasicTable rows={rows} headCells={headCells} />
          <RowWrapper style={{ justifyContent: 'flex-end' }}>
            <SpaceContainer vertical={false}>
              <PrimaryButton>Otwórz szczegóły</PrimaryButton>
            </SpaceContainer>
            <SpaceContainer vertical={false}>
              <PrimaryButton>Generuj raport</PrimaryButton>
            </SpaceContainer>
            <SpaceContainer vertical={false}>
              <PrimaryButton>Serwis</PrimaryButton>
            </SpaceContainer>
            <SpaceContainer vertical={false}>
              <PrimaryButton>Przegląd</PrimaryButton>
            </SpaceContainer>
          </RowWrapper>
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default Notes;
