import React from 'react';
import PrimaryButton from '../../../../common/buttons/PrimaryButton';
import { TextField, Typography, Avatar, Paper } from '@material-ui/core';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import RowWrapper from '../../../../common/wrappers/RowWrapper';
import ExpansionContainer from '../../../../common/containers/ExpansionContainer';

const notes = [
  {
    user: {
      name: 'Piotr',
      img:
        'https://images.unsplash.com/photo-1586297098710-0382a496c814?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
    },
    date: '21.02.2020',
    content: 'Lorem ipsum dolor sit amet, consetetur sadipdc eorl.',
  },
];

const Notes = () => {
  return (
    <ExpansionContainer title="Notatki">
      <ColumnWrapper fullWidth>
        <TextField
          placeholder="Wpisz notatkę ..."
          fullWidth
          multiline
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
        />
        <PrimaryButton style={{ alignSelf: 'flex-end' }}>Dodaj notatkę</PrimaryButton>
        {notes.map((note, i) => (
          <RowWrapper key={`note${i}`}>
            <Paper style={{ padding: 10, margin: '10px 0 20px' }} variant="outlined">
              <RowWrapper>
                <RowWrapper>
                  <Avatar alt="user" src={note.user.img} />
                  <Typography style={{ margin: '0 10px' }} variant="body2">
                    {note.user.name}
                  </Typography>
                </RowWrapper>
                <Typography variant="body2">{note.date}</Typography>
              </RowWrapper>
              <Typography variant="body2">{note.content}</Typography>
            </Paper>
          </RowWrapper>
        ))}
        <PrimaryButton color="secondary" style={{ alignSelf: 'flex-start' }}>
          Wczytaj starsze
        </PrimaryButton>
      </ColumnWrapper>
    </ExpansionContainer>
  );
};

export default Notes;
