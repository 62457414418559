import uniqid from 'uniqid'

function createData(date, action, serviceMan, warranty, note) {
  return { date, action, serviceMan, warranty, note, id: uniqid() };
}


const rows = [
  createData('20.02.2020', 'naprawianie', 'Maciej Tolarski', '15 miesięcy', 'Notatka'),
  createData('20.02.2020', 'naprawianie', 'Maciej Tolarski', '15 miesięcy', 'Notatka'),
  createData('20.02.2020', 'naprawianie', 'Maciej Tolarski', '15 miesięcy', 'Notatka'),
  createData('20.02.2020', 'naprawianie', 'Maciej Tolarski', '15 miesięcy', 'Notatka'),
  createData('20.02.2020', 'naprawianie', 'Maciej Tolarski', '15 miesięcy', 'Notatka'),
  createData('20.02.2020', 'naprawianie', 'Maciej Tolarski', '15 miesięcy', 'Notatka'),
  createData('20.02.2020', 'naprawianie', 'Maciej Tolarski', '15 miesięcy', 'Notatka'),
  createData('20.02.2020', 'naprawianie', 'Maciej Tolarski', '15 miesięcy', 'Notatka'),
  createData('20.02.2020', 'naprawianie', 'Maciej Tolarski', '15 miesięcy', 'Notatka'),
  createData('20.02.2020', 'naprawianie', 'Maciej Tolarski', '15 miesięcy', 'Notatka'),
  createData('20.02.2020', 'naprawianie', 'Maciej Tolarski', '15 miesięcy', 'Notatka'),
  createData('20.02.2020', 'naprawianie', 'Maciej Tolarski', '15 miesięcy', 'Notatka'),
];

const headCells = [
  { id: 'date', numeric: false, disablePadding: false, label: 'data' },
  { id: 'action', numeric: false, disablePadding: false, label: 'czynność' },
  { id: 'serviceMan', numeric: false, disablePadding: false, label: 'serwisant' },
  { id: 'warranty', numeric: false, disablePadding: false, label: 'gwarancja' },
  { id: 'note', numeric: false, disablePadding: false, label: 'notatka' },
];

export { rows, headCells };