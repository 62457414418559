import React from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/pl';
import routerHistory from '../../settings/routerHistory';
import CalendarToolBar from '../../components/screen-components/calendar/tool-bar/CalendarToolBar';

moment.locale('pl');
const localizer = momentLocalizer(moment);

const CalendarScreen = () => {
  const handleSelect = ({ start, end }) => {
    routerHistory.push('/calendar/add-slot', { start, end });
  };

  return (
    <>
      <CalendarToolBar />
      <Calendar
        style={{ height: '80vh' }}
        selectable
        localizer={localizer}
        events={[]}
        messages={{
          next: 'Następny',
          previous: 'Poprzedni',
          today: 'Dzisiaj',
          week: 'Tydzień',
          month: 'Miesiąc',
          day: 'Dzień',
          noEventsInRange: 'Brak wydarzeń w zaznaczonym terminie',
        }}
        defaultView={Views.MONTH}
        scrollToTime={new Date(1970, 1, 1, 6)}
        defaultDate={new Date()}
        onSelectEvent={(event) => alert(event.title)}
        onSelectSlot={handleSelect}
      />
    </>
  );
};

export default CalendarScreen;
