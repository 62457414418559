const sectionOne = [
  { label: 'Frima' },
  { label: 'Os kontaktowa' },
  { label: 'Projekt' },
  { label: 'Wariant urz.' },
  {
    label: 'Wariant automatyki pod wariant', type: 'multipleFields', options: {
      fields: [
        {},
        { label: 'Gwarancja' },
      ]
    },
  },
];

const sectionTwo = [{
  label: '1. Ważność oferty',
  input: true
},
{
  label: '2. Czas realizacji zamówienia - podział na producentów',
  fields: [{
    label: 'A. Urządzenia'
  }, {
    label: 'B. Automatyka',
    secondLabel: 'dni roboczych od momentu złożenia zamóiwenia, uregulowania przedpłaty oraz dostarczenia kompletnego zestawienia elementów wykonawczych'
  }, {
    label: 'C. Komponenty',
    secondLabel: 'tygodni'
  }],
},
{
  label: '3. Warunki płatności',
  fields: [{
    label: 'A. Urządzenia'
  }, {
    label: 'B. Automatyka',
  }, {
    label: 'C. Komponenty',
  }],
}, {
  label: '4. Dostawa'
},
{
  label: '5. Gwarancja',
  fields: [{
    label: 'A. Urządzenia'
  }, {
    label: 'B. Automatyka',
  }, {
    label: 'C. Komponenty',
  }],
}, {
  label: '5. Dostawa',
  input: true
}, {
  label: '6. Obowiązkowe uruchomienie urządzenia przez autoryzowany serwis.'
}, {
  label: '7. Rozruch urządzeń przez ASR (Autoryzaowany Serwis Regionalny): zł netto + dojazd (1,2 zł/km netto liczony w obie strony).'
}, {
  label: '8. Szczegóły załączonej dokumentacji.'
}, {
  label: '9. Wycenia nie obejmuje automatyki sterującej, czujników temperatury, okablowania, sterowników.'
}, {
  label: '10. Wycena nie obejmuje kosztów okablowania.'
}, {
  label: '11. Przy zastosowaniu trzech lub więcej urządzeń Robur wymagany jest przewód kuminkacyjny ROBUR NETBUS, do położenia instalacji między zestawami urządzeń, a miejscem instalcji szafy automatyki (cena netto 7 EUR za mb).'
}
];

export { sectionOne, sectionTwo };