import uniqid from 'uniqid'
import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import styled from 'styled-components';

function createData(type, company, project, date, forPerson, from, description, status) {
  return { type, company, project, date, forPerson, from, description, status, id: uniqid() };
}

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 8px;
  }
`

const userImg = 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'
const personImg = 'https://images.unsplash.com/photo-1552072805-2a9039d00e57?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'

const returnUser = (img, name) => <UserInfo><Avatar alt="Remy Sharp" src={img} /><p>{name}</p></UserInfo>

const rows = [
  createData('Spotkanie', 'Firma B', 'Projekt 131Az', '20.02.2020', returnUser(userImg, 'Piotr'), returnUser(personImg, 'Marek'), 'Opis', 'Oficjalny'),
  createData('Spotkanie', 'Firma B', 'Projekt 131Az', '20.02.2020', returnUser(userImg, 'Piotr'), returnUser(personImg, 'Marek'), 'Opis', 'Oficjalny'),
  createData('Spotkanie', 'Firma B', 'Projekt 131Az', '20.02.2020', returnUser(userImg, 'Piotr'), returnUser(personImg, 'Marek'), 'Opis', 'Oficjalny'),
  createData('Spotkanie', 'Firma B', 'Projekt 131Az', '20.02.2020', returnUser(userImg, 'Piotr'), returnUser(personImg, 'Marek'), 'Opis', 'Oficjalny'),
  createData('Spotkanie', 'Firma B', 'Projekt 131Az', '20.02.2020', returnUser(userImg, 'Piotr'), returnUser(personImg, 'Marek'), 'Opis', 'Oficjalny'),
  createData('Spotkanie', 'Firma B', 'Projekt 131Az', '20.02.2020', returnUser(userImg, 'Piotr'), returnUser(personImg, 'Marek'), 'Opis', 'Oficjalny'),
  createData('Spotkanie', 'Firma B', 'Projekt 131Az', '20.02.2020', returnUser(userImg, 'Piotr'), returnUser(personImg, 'Marek'), 'Opis', 'Oficjalny'),
  createData('Spotkanie', 'Firma B', 'Projekt 131Az', '20.02.2020', returnUser(userImg, 'Piotr'), returnUser(personImg, 'Marek'), 'Opis', 'Oficjalny'),
  createData('Spotkanie', 'Firma B', 'Projekt 131Az', '20.02.2020', returnUser(userImg, 'Piotr'), returnUser(personImg, 'Marek'), 'Opis', 'Oficjalny'),
  createData('Spotkanie', 'Firma B', 'Projekt 131Az', '20.02.2020', returnUser(userImg, 'Piotr'), returnUser(personImg, 'Marek'), 'Opis', 'Oficjalny'),
  createData('Spotkanie', 'Firma B', 'Projekt 131Az', '20.02.2020', returnUser(userImg, 'Piotr'), returnUser(personImg, 'Marek'), 'Opis', 'Oficjalny'),
  createData('Spotkanie', 'Firma B', 'Projekt 131Az', '20.02.2020', returnUser(userImg, 'Piotr'), returnUser(personImg, 'Marek'), 'Opis', 'Oficjalny'),
  createData('Spotkanie', 'Firma B', 'Projekt 131Az', '20.02.2020', returnUser(userImg, 'Piotr'), returnUser(personImg, 'Marek'), 'Opis', 'Oficjalny'),
  createData('Spotkanie', 'Firma B', 'Projekt 131Az', '20.02.2020', returnUser(userImg, 'Piotr'), returnUser(personImg, 'Marek'), 'Opis', 'Oficjalny'),
  createData('Spotkanie', 'Firma B', 'Projekt 131Az', '20.02.2020', returnUser(userImg, 'Piotr'), returnUser(personImg, 'Marek'), 'Opis', 'Oficjalny'),
];

const headCells = [
  { id: 'type', numeric: false, disablePadding: false, label: 'typ' },
  { id: 'company', numeric: false, disablePadding: false, label: 'firma' },
  { id: 'project', numeric: false, disablePadding: false, label: 'projekt' },
  { id: 'date', numeric: false, disablePadding: false, label: 'data' },
  { id: 'forPerson', numeric: false, disablePadding: false, label: 'dla' },
  { id: 'from', numeric: false, disablePadding: false, label: 'od' },
  { id: 'description', numeric: false, disablePadding: false, label: 'opis' },
  { id: 'status', numeric: false, disablePadding: false, label: 'stan' },
];

export { rows, headCells };