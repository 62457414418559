const automationField = [{
  label: 'Jaka',
}, {
  label: 'Gwarancja',
}, {
  label: 'Cena',
}, {
  label: 'Załacz opis',
  type: 'button'
}, {
  label: 'Dodaj kolejne',
  type: 'button'
}, {
  label: 'Dodaj wariant',
  type: 'button'
}];

const durabilityFieds = [{
  label: 'c.o.',
  unitType: 'kW temp pracy',
  fields: [{
    label: 'Zasilanie',
  }, {
    label: 'Powrót',
    unitType: '℃'
  }]
}, {
  label: 'c.t.',
  unitType: 'kW temp pracy',
  fields: [{
    label: 'Zasilanie',
  }, {
    label: 'Powrót',
    unitType: '℃'
  }]
}, {
  label: 'Suma',
  unitType: 'kW',
}, {
  label: 'c.w.u.',
  unitType: 'kW',
}, {
  label: 'Suma',
  unitType: 'kW',
}];

export { automationField, durabilityFieds };