import React from 'react';
import { rows, headCells } from './tableData';
import ExpansionContainer from '../../../../common/containers/ExpansionContainer';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import BasicTable from '../../../../common/tables/basic/BasicTable';

const Cost = () => {
  return (
    <>
      <ExpansionContainer title="Tabela kosztów">
        <ColumnWrapper fullWidth>
          <BasicTable rows={rows} headCells={headCells} />
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default Cost;
