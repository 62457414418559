import React from 'react';
import ExpansionContainer from '../../../../common/containers/ExpansionContainer';
import BasicTable from '../../../../common/tables/basic/BasicTable';
import { headCells, rows } from './tableData';

const OffersData = () => {
  return (
    <>
      <ExpansionContainer title="Oferty">
        <div style={{ maxWidth: '1000px', overflowX: 'auto' }}>
          <BasicTable headCells={headCells} rows={rows} />
        </div>
      </ExpansionContainer>
    </>
  );
};

export default OffersData;
