import React from 'react';
import ColumnWrapper from '../../components/common/wrappers/ColumnWrapper';
import AutomationToolBar from '../../components/screen-components/projects/automotion/tool-bar/AutomationToolBar';
import InstaltionData from '../../components/screen-components/projects/automotion/instalation-data/InstalationData';
import OfferData from '../../components/screen-components/projects/automotion/offer-data/OfferData';
import CanBusData from '../../components/screen-components/projects/automotion/can-bus-data/CanBusData';
import OtherData from '../../components/screen-components/projects/automotion/other-data/OtherData';
import FloatingSaveBar from '../../components/screen-components/shared/FloatingSaveBar';

const AutomotionScreen = () => {
  return (
    <>
      <ColumnWrapper>
        <AutomationToolBar />
        <InstaltionData />
        <OfferData />
        <CanBusData />
        <OtherData />
      </ColumnWrapper>
      <FloatingSaveBar />
    </>
  );
};

export default AutomotionScreen;
