import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  margin: ${(props) => (props.vertical ? '10px 0' : '0 10px')};
`;

const SpaceContainer = ({ children, vertical }) => (
  <Container vertical={vertical}>{children}</Container>
);

SpaceContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  vertical: PropTypes.bool,
};

SpaceContainer.defaultProps = {
  vertical: true,
};

export default SpaceContainer;
