import React from 'react';
import RowWrapper from '../../../common/wrappers/RowWrapper';
import styled from 'styled-components';
import ExpansionContainer from '../../../common/containers/ExpansionContainer';
import ColumnWrapper from '../../../common/wrappers/ColumnWrapper';
import CompanyLabel from '../../../unique/note-labels/CompanyLabel';
import ProjectLabel from '../../../unique/note-labels/ProjectLabel';
import { Typography } from '@material-ui/core';

const ItemHeader = styled(RowWrapper)`
  background: #1d3b57;
  padding: 5px 10px;
  border-radius: 2px;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const HeaderText = styled(Typography)`
  color: #fff;
`;

const EventsBoard = () => {
  return (
    <>
      <RowWrapper fullWidth style={{ padding: '0 10px' }}>
        <ExpansionContainer title="Dzisiejsze wydarzenia">
          <ColumnWrapper fullWidth>
            <ItemHeader fullWidth>
              <HeaderText variant="body2">Nazwa wydarzenia</HeaderText>
              <HeaderText variant="body2">Cały dzień</HeaderText>
            </ItemHeader>
            <RowWrapper>
              <CompanyLabel companyName="Firma A" />
              <ProjectLabel projectName="Projekt" />
            </RowWrapper>
          </ColumnWrapper>
        </ExpansionContainer>
      </RowWrapper>
    </>
  );
};

export default EventsBoard;
