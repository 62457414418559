import React from 'react';
import fields from './fields';
import DynamicForm from '../../../../common/dynamic-forms/DynamicForm';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';

const EventForm = () => {
  return (
    <>
      <ColumnWrapper fullWidth>
        <DynamicForm fields={fields} />
      </ColumnWrapper>
    </>
  );
};

export default EventForm;
