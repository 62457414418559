import React from 'react';
import styled from 'styled-components';
import { TextField, Button, Fab } from '@material-ui/core';
import RowWrapper from '../../common/wrappers/RowWrapper';
import userMock from '../../../mocks/userMock';
import NoteItem from './NoteItem';
import ColumnWrapper from '../../common/wrappers/ColumnWrapper';
import AddIcon from '@material-ui/icons/Add';
import TextsmsIcon from '@material-ui/icons/Textsms';
import WorkIcon from '@material-ui/icons/Work';
import ExpansionContainer from '../../common/containers/ExpansionContainer';

const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

const TextFieldsWrapper = styled(RowWrapper)`
  margin-top: ${(props) => (props.small ? '10px' : '25px')};
`;

const NotesPanel = () => {
  return (
    <>
      <ExpansionContainer title="Notatki">
        <ColumnWrapper fullWidth>
          <ColumnWrapper fullWidth>
            <NoteItem
              user={userMock}
              note={userMock.description}
              comapnyLabels={['firma A', 'firma B']}
            />
            <NoteItem
              user={userMock}
              note={userMock.description}
              projectLabels={['projekt']}
              comapnyLabels={['firma A', 'firma B']}
            />
          </ColumnWrapper>
          <TextFieldsWrapper fullWidth>
            <TextField
              style={{ margin: 8 }}
              placeholder="Wpisz notatkę"
              fullWidth
              multiline
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
            />
            <ButtonWrapper>
              <Fab color="primary" aria-label="add">
                <AddIcon />
              </Fab>
            </ButtonWrapper>
          </TextFieldsWrapper>
          <TextFieldsWrapper small>
            <ButtonWrapper>
              <Button
                startIcon={<TextsmsIcon />}
                variant="contained"
                style={{ color: 'white' }}
                size="small"
                color="primary">
                Szablon
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button startIcon={<WorkIcon />} variant="outlined" size="small" color="primary">
                Powiąż projekt
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button startIcon={<WorkIcon />} variant="outlined" size="small" color="primary">
                Powiąż firmę
              </Button>
            </ButtonWrapper>
          </TextFieldsWrapper>
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default NotesPanel;
