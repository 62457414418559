import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, fade } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.primary.main, 0.05),
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.15),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const SearchField = () => {
  const { search, searchIcon, inputRoot, inputInput } = useStyles();
  return (
    <>
      <div className={search}>
        <div className={searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Wyszukaj"
          classes={{
            root: inputRoot,
            input: inputInput,
          }}
          inputProps={{ 'aria-label': 'wyszukaj' }}
        />
      </div>
    </>
  );
};

export default SearchField;
