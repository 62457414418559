import React from 'react';
import { rows, headCells } from './tableData';
import ExpansionContainer from '../../../../common/containers/ExpansionContainer';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import BasicTable from '../../../../common/tables/basic/BasicTable';
import PrimaryButton from '../../../../common/buttons/PrimaryButton';

const Offer = () => {
  return (
    <>
      <ExpansionContainer title="Oferta">
        <ColumnWrapper fullWidth>
          <BasicTable rows={rows} headCells={headCells} />
          <PrimaryButton style={{ alignSelf: 'flex-end' }}>Generuj ofertę</PrimaryButton>
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default Offer;
