import React from 'react';
import SearchField from '../../../common/inputs/search-field/SearchField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { PROJECT_TYPES, PROJECT_DATE } from '../../../../mocks/projectsToolBarMock';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import routerHistory from '../../../../settings/routerHistory';
import { InputWrapper, Wrapper } from '../../shared/ListToolBarComponents';

const CalendarToolBar = () => {
  return (
    <>
      <Wrapper>
        <InputWrapper>
          <Select
            style={{ backgroundColor: '#f2f2f3' }}
            variant="filled"
            IconComponent={ArrowDropDownIcon}
            labelId="label"
            id="select"
            value={PROJECT_TYPES[0].value}>
            {PROJECT_TYPES.map(({ id, label, value }) => (
              <MenuItem key={id} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </InputWrapper>
        <InputWrapper>
          <Select
            style={{ backgroundColor: '#f2f2f3' }}
            variant="filled"
            IconComponent={ArrowDropDownIcon}
            labelId="label"
            id="select"
            value={PROJECT_DATE[0].value}>
            {PROJECT_DATE.map(({ id, label, value }) => (
              <MenuItem key={id} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </InputWrapper>
        <InputWrapper>
          <SearchField />
        </InputWrapper>
        <PrimaryButton onClick={() => routerHistory.push('/calendar/add-slot')}>
          Dodaj
        </PrimaryButton>
      </Wrapper>
    </>
  );
};

export default CalendarToolBar;
