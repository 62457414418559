import uniqid from 'uniqid'
import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import styled from 'styled-components';

function createData(type, name, added, assigned) {
  return { type, name, added, assigned, id: uniqid() };
}

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 8px;
  }
`

const userImg = 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'

const returnUser = () => <UserInfo><Avatar alt="Remy Sharp" src={userImg} /><p>Piotr</p></UserInfo>

const rows = [
  createData('pdf', 'Nazwa pliku', '20.03.2020, 13:30', returnUser()),
  createData('pdf', 'Nazwa pliku', '20.03.2020, 13:30', returnUser()),
  createData('pdf', 'Nazwa pliku', '20.03.2020, 13:30', returnUser()),
  createData('pdf', 'Nazwa pliku', '20.03.2020, 13:30', returnUser()),
  createData('pdf', 'Nazwa pliku', '20.03.2020, 13:30', returnUser()),
  createData('pdf', 'Nazwa pliku', '20.03.2020, 13:30', returnUser()),
  createData('pdf', 'Nazwa pliku', '20.03.2020, 13:30', returnUser()),
  createData('pdf', 'Nazwa pliku', '20.03.2020, 13:30', returnUser()),
  createData('pdf', 'Nazwa pliku', '20.03.2020, 13:30', returnUser()),
  createData('pdf', 'Nazwa pliku', '20.03.2020, 13:30', returnUser()),
  createData('pdf', 'Nazwa pliku', '20.03.2020, 13:30', returnUser()),
  createData('pdf', 'Nazwa pliku', '20.03.2020, 13:30', returnUser()),
];

const headCells = [
  { id: 'type', numeric: false, disablePadding: false, label: 'typ' },
  { id: 'name', numeric: false, disablePadding: false, label: 'nazwa' },
  { id: 'added', numeric: false, disablePadding: false, label: 'dodano' },
  { id: 'assigned', numeric: false, disablePadding: false, label: 'przez' },
];

export { rows, headCells };