import React from 'react';
import { Button } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import RowWrapper from '../../../common/wrappers/RowWrapper';
import BackButton from '../../../common/buttons/back-button/BackButton';
import routerHistory from '../../../../settings/routerHistory';
import { Wrapper, ButtonWrapper } from '../../shared/DetailsToolBarComponents';

const DetailsToolBar = () => {
  return (
    <>
      <Wrapper>
        <BackButton text="Wróć do listy" onClick={() => routerHistory.push('/companies')} />
        <RowWrapper>
          <ButtonWrapper>
            <Button
              startIcon={<AssignmentIcon />}
              style={{ color: 'white' }}
              variant="contained"
              onClick={() => routerHistory.push('/projects')}
              color="secondary">
              Projekty
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              startIcon={<FindInPageIcon />}
              style={{ color: 'white' }}
              variant="contained"
              onClick={() => routerHistory.push('/offers')}
              color="secondary">
              Oferty
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              startIcon={<DoneAllIcon />}
              style={{ color: 'white' }}
              variant="contained"
              onClick={() => routerHistory.push('/tasks')}
              color="secondary">
              Zadania
            </Button>
          </ButtonWrapper>
        </RowWrapper>
      </Wrapper>
    </>
  );
};

export default DetailsToolBar;
