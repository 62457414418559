import React from 'react';
import RowWrapper from '../../../common/wrappers/RowWrapper';
import ExpansionContainer from '../../../common/containers/ExpansionContainer';
import ColumnWrapper from '../../../common/wrappers/ColumnWrapper';
import { Checkbox } from '@material-ui/core';
import { ItemContainer, Text } from '../share/ShareComponents';

const ProjectsBoard = () => {
  return (
    <>
      <RowWrapper fullWidth style={{ padding: '0 10px' }}>
        <ExpansionContainer title="Projekty">
          <ColumnWrapper>
            <RowWrapper>
              <Checkbox />
              <ItemContainer>
                <Text variant="body2">Nazwa firmy</Text>
                <Text style={{ fontWeight: 'bold' }} variant="body2">
                  Nazwa projektu
                </Text>
                <Text variant="body2">Bydgoszcz (KUJ-POM)</Text>
                <Text variant="body2">21.02.2020</Text>
              </ItemContainer>
            </RowWrapper>
          </ColumnWrapper>
        </ExpansionContainer>
      </RowWrapper>
    </>
  );
};

export default ProjectsBoard;
