import React from 'react';
import RowWrapper from '../../../common/wrappers/RowWrapper';
import { Avatar, Typography } from '@material-ui/core';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const ToolBar = () => {
  return (
    <>
      <RowWrapper style={{ justifyContent: 'space-between' }}>
        <RowWrapper>
          <Avatar
            alt="user"
            src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
          />
          <Typography style={{ margin: '0 0 0 5px' }} paragraph>
            Piotr
          </Typography>
        </RowWrapper>
        <PrimaryButton color="secondary" icon={<ExitToAppIcon />}>
          Wyloguj
        </PrimaryButton>
      </RowWrapper>
    </>
  );
};

export default ToolBar;
