import React from 'react';
import { companyRows, companyHeadCells } from '../../mocks/productsTableMock';
import routerHistory from '../../settings/routerHistory';
import BasicTable from '../../components/common/tables/basic/BasicTable';
import ListToolBar from '../../components/screen-components/products/list/ListToolBar';

const ProductsScreen = () => (
  <>
    <ListToolBar />
    <BasicTable
      headCells={companyHeadCells}
      rows={companyRows}
      onRowClick={({ id }) => routerHistory.push(`/products/${id}/categories`)}
    />
  </>
);

export default ProductsScreen;
