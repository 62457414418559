import React from 'react';
import BackButton from '../../../common/buttons/back-button/BackButton';
import routerHistory from '../../../../settings/routerHistory';
import { Wrapper } from '../../shared/DetailsToolBarComponents';

const DetailsToolBar = () => {
  return (
    <>
      <Wrapper>
        <BackButton text="Wróć do listy" onClick={() => routerHistory.push('/companies')} />
      </Wrapper>
    </>
  );
};

export default DetailsToolBar;
