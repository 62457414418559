const fields = [
  {
    label: 'Odebranie szafy', type: 'multipleFields', options: {
      fields: [
        { type: 'checkbox' },
        { label: 'Data' },
      ]
    },
  },
  {
    label: 'Wysłanie szafy', type: 'multipleFields', options: {
      fields: [
        { type: 'checkbox' },
        { label: 'Data' },
      ]
    },
  },
  {
    label: 'Zestawienie przewodów', type: 'multipleFields', options: {
      fields: [
        { type: 'button', options: { text: 'Plik PDF' } },
        { type: 'checkbox' },
      ]
    },
  },
  {
    label: 'Instrukcja konfiguracji', type: 'multipleFields', options: {
      fields: [
        { type: 'button', options: { text: 'Plik PDF' } },
        { type: 'checkbox' },
      ]
    },
  },
  {
    label: 'Zdjęcia szafy', type: 'button', options: {
      text: 'Zdjęcie'
    },
  },
  {
    label: 'Instrukcja obsługi szafy', type: 'multipleFields', options: {
      fields: [
        { type: 'button', options: { text: 'Plik PDF' } },
        { type: 'checkbox' },
      ]
    },
  },
  { label: 'Koszt szafy auotmatyki' },
  { label: 'Koszt elementów auotmatyki' },
  {
    label: 'Koszt uruchomienia', type: 'multipleFields', options: {
      fields: [
        {},
        { type: 'checkbox' },
      ]
    },
  },
  { label: 'Koszt szafy auotmatyki' },
  { label: 'Koszt całkowity' },
  {
    label: 'Zysk', type: 'multipleFields', options: {
      fields: [
        {},
        { label: 'Marża' },
      ]
    },
  },
];

export default fields;