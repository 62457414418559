import React from 'react';
import { Typography, Button, Select, MenuItem } from '@material-ui/core';
import { useParams } from 'react-router';
import styled from 'styled-components';
import RowWrapper from '../../../../common/wrappers/RowWrapper';
import BackButton from '../../../../common/buttons/back-button/BackButton';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import ActiveStatus from '../../list/ActiveStatus';
import routerHistory from '../../../../../settings/routerHistory';
import DescriptionIcon from '@material-ui/icons/Description';
import { InputWrapper, InputLabel } from '../../../shared/FormComponents';
import select from '../../../../../mocks/selectMock';

const Wrapper = styled(RowWrapper)`
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

const AutomationToolBar = () => {
  const { id } = useParams();

  return (
    <>
      <Wrapper>
        <RowWrapper>
          <BackButton
            text="Wróć do projektu"
            onClick={() => routerHistory.push(`/projects/${id}`)}
          />
          <ColumnWrapper>
            <Typography>Projekt: {id}</Typography>
            <ActiveStatus />
          </ColumnWrapper>
        </RowWrapper>
        <RowWrapper>
          <InputWrapper>
            <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle1">
              Stan zamówienia
            </InputLabel>
            <Select
              style={{ backgroundColor: '#f2f2f3', width: '200px' }}
              variant="filled"
              labelId="label"
              id="select"
              value={[select.data[0].value]}>
              {select.data.map((dataItem) => (
                <MenuItem key={dataItem.id} value={dataItem.value}>
                  {dataItem.label}
                </MenuItem>
              ))}
            </Select>
          </InputWrapper>
          <ButtonWrapper>
            <Button
              startIcon={<DescriptionIcon />}
              style={{ color: 'white' }}
              variant="contained"
              color="secondary">
              Ofertowanie
            </Button>
          </ButtonWrapper>
        </RowWrapper>
      </Wrapper>
    </>
  );
};

export default AutomationToolBar;
