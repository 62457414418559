import React from 'react';
import { Wrapper, InputWrapper } from '../../shared/ListToolBarComponents';
import routerHistory from '../../../../settings/routerHistory';
import BackButton from '../../../common/buttons/back-button/BackButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';

const ListToolBar = () => {
  return (
    <>
      <Wrapper style={{ justifyContent: 'space-between' }}>
        <InputWrapper>
          <BackButton text="Wróć" onClick={() => routerHistory.push('/marketing/companies')} />
        </InputWrapper>
        <PrimaryButton onClick={() => {}}>Zapisz</PrimaryButton>
      </Wrapper>
    </>
  );
};

export default ListToolBar;
