const fields = [
  {
    type: 'multipleFields', label: 'Data uruchomienia', options: {
      fields: [
        {},
        { label: 'Data startu gwarancji' },
        { label: 'Gwarancja' },
      ]
    },
  },
  {
    type: 'multipleFields', label: 'Data ost. przeglądu', options: {
      fields: [
        {},
        { label: 'Przegląd po roku' },
      ]
    },
  },
  { label: 'Data nast. przeglądu' },
];

export default fields;