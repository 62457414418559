import uniqid from 'uniqid'
import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import styled from 'styled-components';

function createData(type, company, city, number, forPerson, date, personalized, operations) {
  return { type, company, city, number, forPerson, date, personalized, operations, id: uniqid() };
}

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 8px;
  }
`

const userImg = 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'

const returnUser = (img, name) => <UserInfo><Avatar alt="Remy Sharp" src={img} /><p>{name}</p></UserInfo>

const rows = [
  createData('aktywna', 'Firma A', 'Wrocław', '885 321 459', returnUser(userImg, 'Piotr'), '20-02-2020', 'Osoby X', 'Operacje Z'),
  createData('aktywna', 'Firma A', 'Wrocław', '885 321 459', returnUser(userImg, 'Piotr'), '20-02-2020', 'Osoby X', 'Operacje Z'),
  createData('aktywna', 'Firma A', 'Wrocław', '885 321 459', returnUser(userImg, 'Piotr'), '20-02-2020', 'Osoby X', 'Operacje Z'),
  createData('aktywna', 'Firma A', 'Wrocław', '885 321 459', returnUser(userImg, 'Piotr'), '20-02-2020', 'Osoby X', 'Operacje Z'),
  createData('aktywna', 'Firma A', 'Wrocław', '885 321 459', returnUser(userImg, 'Piotr'), '20-02-2020', 'Osoby X', 'Operacje Z'),
  createData('aktywna', 'Firma A', 'Wrocław', '885 321 459', returnUser(userImg, 'Piotr'), '20-02-2020', 'Osoby X', 'Operacje Z'),
  createData('aktywna', 'Firma A', 'Wrocław', '885 321 459', returnUser(userImg, 'Piotr'), '20-02-2020', 'Osoby X', 'Operacje Z'),
  createData('aktywna', 'Firma A', 'Wrocław', '885 321 459', returnUser(userImg, 'Piotr'), '20-02-2020', 'Osoby X', 'Operacje Z'),
  createData('aktywna', 'Firma A', 'Wrocław', '885 321 459', returnUser(userImg, 'Piotr'), '20-02-2020', 'Osoby X', 'Operacje Z'),
  createData('aktywna', 'Firma A', 'Wrocław', '885 321 459', returnUser(userImg, 'Piotr'), '20-02-2020', 'Osoby X', 'Operacje Z'),
  createData('aktywna', 'Firma A', 'Wrocław', '885 321 459', returnUser(userImg, 'Piotr'), '20-02-2020', 'Osoby X', 'Operacje Z'),
  createData('aktywna', 'Firma A', 'Wrocław', '885 321 459', returnUser(userImg, 'Piotr'), '20-02-2020', 'Osoby X', 'Operacje Z'),
  createData('aktywna', 'Firma A', 'Wrocław', '885 321 459', returnUser(userImg, 'Piotr'), '20-02-2020', 'Osoby X', 'Operacje Z'),
  createData('aktywna', 'Firma A', 'Wrocław', '885 321 459', returnUser(userImg, 'Piotr'), '20-02-2020', 'Osoby X', 'Operacje Z'),
  createData('aktywna', 'Firma A', 'Wrocław', '885 321 459', returnUser(userImg, 'Piotr'), '20-02-2020', 'Osoby X', 'Operacje Z'),
  createData('aktywna', 'Firma A', 'Wrocław', '885 321 459', returnUser(userImg, 'Piotr'), '20-02-2020', 'Osoby X', 'Operacje Z'),
];

const headCells = [
  { id: 'type', numeric: false, disablePadding: false, label: 'typ' },
  { id: 'company', numeric: false, disablePadding: false, label: 'firma' },
  { id: 'city', numeric: false, disablePadding: false, label: 'miasto' },
  { id: 'number', numeric: false, disablePadding: false, label: 'numer' },
  { id: 'forPerson', numeric: false, disablePadding: false, label: 'dla' },
  { id: 'date', numeric: false, disablePadding: false, label: 'data' },
  { id: 'personalized', numeric: false, disablePadding: false, label: 'dotczy' },
  { id: 'operations', numeric: false, disablePadding: false, label: 'operacje' },
];

export { rows, headCells };