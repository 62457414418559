import uniqid from 'uniqid'
import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import styled from 'styled-components';

function createData(status, company, name, city, added, edited, assigned) {
  return { status, company, name, city, added, edited, assigned, id: uniqid() };
}

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 8px;
  }
`

const userImg = 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'

const returnUser = () => <UserInfo><Avatar alt="Remy Sharp" src={userImg} /><p>Piotr</p></UserInfo>

const rows = [
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
  createData('Spotkanie', 'Nazwa Firmy', 'Nazwa projektu', 'Bydgoszcz (KUJ-POM)', '21.02.2020', '21.02.2020', returnUser()),
];

const headCells = [
  { id: 'status', numeric: false, disablePadding: false, label: 'status' },
  { id: 'company', numeric: false, disablePadding: false, label: 'firma' },
  { id: 'name', numeric: false, disablePadding: false, label: 'nazwa' },
  { id: 'city', numeric: false, disablePadding: false, label: 'miasto' },
  { id: 'added', numeric: false, disablePadding: false, label: 'dodano' },
  { id: 'edited', numeric: false, disablePadding: false, label: 'modyfikowano' },
  { id: 'assigned', numeric: false, disablePadding: false, label: 'przypisany' },
];

export { rows, headCells };