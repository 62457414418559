import React from 'react';
import { useParams } from 'react-router';
import { Divider, Typography } from '@material-ui/core';
import { sectionOne } from './fields';
import ColumnWrapper from '../../../common/wrappers/ColumnWrapper';
import ExpansionContainer from '../../../common/containers/ExpansionContainer';
import DynamicForm from '../../../common/dynamic-forms/DynamicForm';
import FloatingSaveBar from '../../shared/FloatingSaveBar';
import { FormWrapper } from '../../shared/FormComponents';
import { headCells, rows } from '../../../../mocks/offersDetailsTableMock';
import BasicTable from '../../../common/tables/basic/BasicTable';
import Form from './form/Form';

const OfferDetailsData = () => {
  const { id } = useParams();
  return (
    <>
      <ExpansionContainer title="Dane oferty">
        <ColumnWrapper fullWidth>
          <Typography variant="body1">Nr oferty: {id}</Typography>
          <DynamicForm fields={sectionOne} />
          <Divider />
          <FormWrapper>
            <BasicTable headCells={headCells} rows={rows} />
          </FormWrapper>
          <FloatingSaveBar />
          <Form />
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default OfferDetailsData;
