import React from 'react';
import fields from './fields';
import ExpansionContainer from '../../../../common/containers/ExpansionContainer';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import DynamicForm from '../../../../common/dynamic-forms/DynamicForm';
import { summaryRows, notesRows, summaryHeadCells, notesHeadCells } from './tableData';
import BasicTable from '../../../../common/tables/basic/BasicTable';

const OtherData = () => {
  return (
    <>
      <ExpansionContainer title="Pozostałe dane">
        <ColumnWrapper fullWidth>
          <BasicTable headCells={summaryHeadCells} rows={summaryRows} />
          <DynamicForm fields={fields} />
        </ColumnWrapper>
      </ExpansionContainer>
      <ExpansionContainer title="Notatki">
        <BasicTable headCells={notesHeadCells} rows={notesRows} />
      </ExpansionContainer>
    </>
  );
};

export default OtherData;
