import React, { useState } from 'react';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import PrimaryButton from '../../../../common/buttons/PrimaryButton';
import { Avatar, Typography } from '@material-ui/core';
import Select from '../../../../common/dynamic-forms/form-components/Select';
import RowWrapper from '../../../../common/wrappers/RowWrapper';

const users = [
  {
    value: 'Piotr',
    label: 'Piotr',
    id: 1,
    img:
      'https://images.unsplash.com/photo-1586297098710-0382a496c814?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
  },
];

const WorkersList = () => {
  const [worksers, setWorkers] = useState([]);
  const [selectedWorker, setSelectedWorker] = useState(null);

  const isWorkerExistsOnTheList = () => {
    return worksers.find((w) => w.id === selectedWorker.id) ? true : false;
  };

  const addWorker = () => {
    if (selectedWorker && !isWorkerExistsOnTheList()) setWorkers([...worksers, selectedWorker]);
  };

  return (
    <>
      <ColumnWrapper style={{ marginRight: 50 }} fullWidth>
        {worksers.map((worker, i) => (
          <RowWrapper style={{ margin: '10px 0 20px' }} key={`worker${i}`}>
            <Avatar style={{ marginRight: 10 }} alt="user" src={worker.img} />
            <Typography variant="body2">{worker.label}</Typography>
          </RowWrapper>
        ))}
        <Typography variant="body2" style={{ marginBottom: 10 }}>
          Wybierz użytkownika
        </Typography>
        <Select data={users} onSelect={(w) => setSelectedWorker(w)} />
        <PrimaryButton style={{ alignSelf: 'flex-end', marginTop: 20 }} onClick={addWorker}>
          Dodaj
        </PrimaryButton>
      </ColumnWrapper>
    </>
  );
};

export default WorkersList;
