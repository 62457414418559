import fetchIntercept from 'fetch-intercept';
import { setIsAuthenticated } from '../../redux/actions/auth';
import { setIndicatorVisible } from '../../redux/actions/indicators';
import routerHistory from '../../settings/routerHistory';
const { toast } = require('react-toastify');

const initialSettings = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const call = async (dispatch, url, method, headers, data) => {
  const token = localStorage.getItem('loginToken');

  const unregister = fetchIntercept.register({
    request: function (url, config) {
      // Modify the url or config here
      dispatch(setIndicatorVisible({ visible: true }));
      return [url, config];
    },

    requestError: function (error) {
      // Called when an error occured during another 'request' interceptor call
      dispatch(setIndicatorVisible({ visible: true }));
      return Promise.reject(error);
    },

    response: function (response) {
      // Modify the reponse object
      dispatch(setIndicatorVisible({ visible: false }));
      return response;
    },

    responseError: function (error) {
      // Handle an fetch error
      dispatch(setIndicatorVisible({ visible: false }));
      return Promise.reject(error);
    },
  });
  try {
    let res = null;
    if (method.toUpperCase() !== 'GET') {
      res = await fetch(url, {
        method: method,
        headers: {
          ...headers,
          'X-Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...data,
        }),
      });
    } else {
      res = await fetch(url, { method, headers });
    }
    const receivedData = await res.json();

    if (
      receivedData.message === 'Expired JWT Token' &&
      receivedData.code === 401 &&
      routerHistory.location.pathname !== '/'
    ) {
      dispatch(setIsAuthenticated({ isAuthenticated: false }));
      routerHistory.push('/');
    }
    unregister();
    return { data: receivedData, status: 'ok' };
  } catch (error) {
    toast.error(`Błąd podczas łączenia się z API ${error}`);
    unregister();
    return { data: null, status: 'error' };
  }
};

const getCall = async (dispatch, url, headers = initialSettings) =>
  await call(dispatch, url, 'GET', headers);

const postCall = async (dispatch, url, data, headers = initialSettings) =>
  await call(dispatch, url, 'POST', headers, data);

const putCall = async (dispatch, url, data, headers = initialSettings) =>
  await call(dispatch, url, 'PUT', headers, data);

const deleteCall = async (dispatch, url, data, headers = initialSettings) =>
  await call(dispatch, url, 'DELETE', headers, data);

const apiCall = {
  getCall,
  postCall,
  putCall,
  deleteCall,
};

export default apiCall;
