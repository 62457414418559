import React from 'react';
import BasicTable from '../../components/common/tables/basic/BasicTable';
import { headCells, rows } from '../../mocks/projectsTableMock';
import routerHistory from '../../settings/routerHistory';
import ListToolBar from '../../components/screen-components/projects/list/ListToolBar';

const ProjectsScreen = () => (
  <>
    <ListToolBar />
    <BasicTable
      headCells={headCells}
      rows={rows}
      onRowClick={({ id }) => routerHistory.push(`/projects/${id}`)}
    />
  </>
);

export default ProjectsScreen;
