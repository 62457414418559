import React from 'react'
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PieChartIcon from '@material-ui/icons/PieChart';
import SettingsIcon from '@material-ui/icons/Settings';


const menuItems = [{
  routeName: '/projects',
  label: 'Projekty',
  icon: () => <AssignmentIcon />, id: 1
},
{
  routeName: '/companies',
  label: 'Firmy',
  icon: () => <BusinessCenterIcon />, id: 2
},
{
  routeName: '/tasks',
  label: 'Zadania',
  icon: () => <DoneAllIcon />, id: 3
},
{
  routeName: '/offers',
  label: 'Oferty',
  icon: () => <FindInPageIcon />, id: 4
}, {
  routeName: '/products',
  label: 'Produkty',
  icon: () => <ShoppingCartIcon />, id: 5
},
{
  routeName: '/documents',
  label: 'Dokumenty',
  icon: () => <AttachFileIcon />, id: 6
},
{
  routeName: '/calendar',
  label: 'Terminarz',
  icon: () => <DateRangeIcon />, id: 7
},
{
  routeName: '/marketing/email-list',
  label: 'Marketing',
  icon: () => <AssessmentIcon />, id: 8,
  isOpen: false,
  subRoutes: [{
    routeName: '/marketing/email-list',
    label: 'Listy mailingowe'
  }, {
    routeName: '/marketing/companies',
    label: 'Baza firm'
  }, {
    routeName: '/marketing/import',
    label: 'Import'
  }]
},
{
  routeName: '/reports',
  label: 'Raporty',
  icon: () => <PieChartIcon />, id: 9
},
{
  routeName: '/administration/users',
  label: 'Administracja',
  icon: () => <SettingsIcon />, id: 10,
  isOpen: false,
  subRoutes: [{
    routeName: '/administration/users',
    label: 'Użytkownicy'
  }, {
    routeName: '/administration/configuration',
    label: 'Konfiguracja'
  }]
},
];

export default menuItems;