import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PrimaryButton from '../../common/buttons/PrimaryButton';

const Bar = styled.div`
  display: flex;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 10px 25px;
  justify-content: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

const FloatingSaveBar = ({ onClick }) => {
  return (
    <>
      <Bar>
        <PrimaryButton onClick={onClick}>Zapisz</PrimaryButton>
      </Bar>
    </>
  );
};

export default FloatingSaveBar;

FloatingSaveBar.propTypes = {
  onClick: PropTypes.func,
};

FloatingSaveBar.defaultProps = {
  onClick: () => {},
};
