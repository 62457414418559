const fields = [
  {
    type: 'multipleFields', label: 'I', options: {
      fields: [
        {},
        { label: 'Nr. ser.' },
      ]
    },
  },
  {
    type: 'multipleFields', label: 'II', options: {
      fields: [
        {},
        { label: 'Nr. ser.' },
      ]
    },
  },
  { label: '1. GAHP-A ID 0' },
  { label: '2. GAHP-A ID 1' },
  { label: '3. GAHP-A ID 2' },
  { label: '4. GAHP-A ID 3' },
];

export default fields;