import React from 'react';
import SearchField from '../../../common/inputs/search-field/SearchField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { PROJECT_TYPES, PROJECT_USERS } from '../../../../mocks/projectsToolBarMock';
import Button from '@material-ui/core/Button';
import { Wrapper, InputWrapper } from '../../shared/ListToolBarComponents';

const ListToolBar = () => {
  return (
    <>
      <Wrapper>
        <InputWrapper>
          <SearchField />
        </InputWrapper>
        <InputWrapper>
          <Select
            style={{ backgroundColor: '#f2f2f3' }}
            variant="filled"
            IconComponent={ArrowDropDownIcon}
            labelId="label"
            id="select"
            value={PROJECT_TYPES[0].value}>
            {PROJECT_TYPES.map(({ id, label, value }) => (
              <MenuItem key={id} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </InputWrapper>
        <InputWrapper>
          <Select
            style={{ backgroundColor: '#f2f2f3' }}
            variant="filled"
            IconComponent={ArrowDropDownIcon}
            labelId="label"
            id="select"
            value={PROJECT_USERS[0].value}>
            {PROJECT_USERS.map(({ id, label, value }) => (
              <MenuItem key={id} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </InputWrapper>
        <Button style={{ color: 'white' }} variant="contained" color="primary">
          Dodaj
        </Button>
      </Wrapper>
    </>
  );
};

export default ListToolBar;
