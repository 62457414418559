import React from 'react';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import ExpansionContainer from '../../../../common/containers/ExpansionContainer';
import { TextField, Divider, InputAdornment, Input } from '@material-ui/core';
import {
  InputLabel,
  InputWrapper,
  Wrapper,
  FormWrapper,
  ColumnWrapperWithRightSpace,
} from '../../../shared/FormComponents';
import { automationField, durabilityFieds } from './fields';
import PrimaryButton from '../../../../common/buttons/PrimaryButton';
import RowWrapper from '../../../../common/wrappers/RowWrapper';
import { Fragment } from 'react';

const AutomationData = () => {
  return (
    <>
      <ExpansionContainer title="Automatyka">
        <ColumnWrapper fullWidth>
          <Wrapper fullWidth>
            <FormWrapper>
              <FormWrapper>
                <InputWrapper>
                  <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                    Automatyka
                  </InputLabel>
                  {automationField.map(({ label, type }) => (
                    <ColumnWrapperWithRightSpace key={label}>
                      {type === 'button' ? (
                        <PrimaryButton size="large">{label}</PrimaryButton>
                      ) : (
                        <>
                          <InputLabel variant="body2">{label}</InputLabel>
                          <TextField variant="filled" />
                        </>
                      )}
                    </ColumnWrapperWithRightSpace>
                  ))}
                </InputWrapper>
              </FormWrapper>
              <Divider />
              <FormWrapper>
                <InputWrapper>
                  <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                    Automatyka
                  </InputLabel>
                  <TextField variant="filled" />
                </InputWrapper>
                <InputWrapper>
                  <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                    Moc
                  </InputLabel>
                  <ColumnWrapper>
                    {durabilityFieds.map(({ label, unitType, fields }, i) => (
                      <RowWrapper key={`${label}_${i}`}>
                        <InputLabel variant="body2">{label}</InputLabel>
                        <Input
                          style={{ marginRight: '50px', padding: '5px' }}
                          endAdornment={<InputAdornment position="end">{unitType}</InputAdornment>}
                        />
                        {fields
                          ? fields.map((field) => (
                              <Fragment key={field.label}>
                                <InputLabel variant="body2">{field.label}</InputLabel>
                                <Input
                                  style={{ marginRight: '50px' }}
                                  endAdornment={
                                    field.unitType ? (
                                      <InputAdornment position="end">
                                        {field.unitType}
                                      </InputAdornment>
                                    ) : null
                                  }
                                />
                              </Fragment>
                            ))
                          : null}
                      </RowWrapper>
                    ))}
                  </ColumnWrapper>
                </InputWrapper>
              </FormWrapper>
              <InputWrapper>
                <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                  Liczba obiegów
                </InputLabel>
                <TextField variant="filled" />
              </InputWrapper>
              <InputWrapper>
                <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                  Rodzaj gazu
                </InputLabel>
                <TextField variant="filled" />
              </InputWrapper>
              <InputWrapper>
                <PrimaryButton>Zapisz projekt</PrimaryButton>
                <div style={{ margin: '0 10px' }}></div>
                <PrimaryButton>Generuj ofertę</PrimaryButton>
              </InputWrapper>
              <InputWrapper>
                <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                  Gwarancja
                </InputLabel>
                <TextField variant="filled" />
              </InputWrapper>
            </FormWrapper>
          </Wrapper>
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default AutomationData;
