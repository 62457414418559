import uniqid from 'uniqid'

function createData(type, price, shouldPayDate, paidDate, paidAmount) {
  return { type, price, shouldPayDate, paidDate, paidAmount, id: uniqid() };
}

const rows = [
  createData('Urządzenia', '10000', '22.01.2020', '23.01.2020', '99999'),
  createData('Wymiernik', '10000', '22.01.2020', '23.01.2020', '99999'),
  createData('Automatyka', '10000', '22.01.2020', '23.01.2020', '99999'),
  createData('Do wpisania', '10000', '22.01.2020', '23.01.2020', '99999'),
];

const headCells = [
  { id: 'type', numeric: false, disablePadding: false, label: 'Typ' },
  { id: 'price', numeric: false, disablePadding: false, label: 'Całkowita kwota brutto z faktury' },
  { id: 'shouldPayDate', numeric: false, disablePadding: false, label: 'Powinni zapłacić DATA' },
  { id: 'paidDate', numeric: false, disablePadding: false, label: 'Zapłacone data' },
  { id: 'paidAmount', numeric: false, disablePadding: false, label: 'Zapłacone kwota' },
];

export { rows, headCells };