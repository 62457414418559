import React from 'react';
import PropTypes from 'prop-types';
import SearchField from '../../../common/inputs/search-field/SearchField';
import { Wrapper, InputWrapper } from '../../shared/ListToolBarComponents';
import { Button, Typography } from '@material-ui/core';
import RowWrapper from '../../../common/wrappers/RowWrapper';
import BackButton from '../../../common/buttons/back-button/BackButton';
import routerHistory from '../../../../settings/routerHistory';

const ListToolBar = ({ type, id }) => {
  return (
    <>
      <Wrapper>
        <RowWrapper fullWidth>
          {type === 'categories' ? (
            <BackButton text="Wróć do listy firm" onClick={() => routerHistory.push('/products')} />
          ) : null}{' '}
          {type === 'companies' ? <Typography variant="body2">Producenci</Typography> : null}
          {type === 'products' ? (
            <BackButton
              text="Wróć do listy kategorii"
              onClick={() => routerHistory.push(`/products/${id}/categories`)}
            />
          ) : null}
        </RowWrapper>
        <InputWrapper>
          <SearchField />
        </InputWrapper>
        <Button style={{ color: 'white' }} variant="contained" color="primary">
          Dodaj
        </Button>
      </Wrapper>
    </>
  );
};

ListToolBar.propTypes = {
  type: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ListToolBar.defaultProps = {
  type: 'companies',
  id: '',
};

export default ListToolBar;
