// import uniqid from 'uniqid'

// function createSummaryData(product, qty, companyWare, number, info) {
//   return { product, qty, companyWare, number, info, id: uniqid() };
// }

// function createNotesData(date, note) {
//   return { date, note, id: uniqid() };
// }

const summaryRows = [];

const notesRows = [];

const summaryHeadCells = [
  { id: 'product', numeric: false, disablePadding: false, label: 'Produkt' },
  { id: 'qty', numeric: false, disablePadding: false, label: 'Ilość' },
  { id: 'companyWare', numeric: false, disablePadding: false, label: 'Firmware' },
  { id: 'number', numeric: false, disablePadding: false, label: 'Nr Seryjny' },
  { id: 'info', numeric: false, disablePadding: false, label: 'Uwagi' },
];

const notesHeadCells = [
  { id: 'date', numeric: false, disablePadding: false, label: 'Data' },
  { id: 'note', numeric: false, disablePadding: false, label: 'Notatka' },
];

export { summaryRows, notesRows, summaryHeadCells, notesHeadCells };