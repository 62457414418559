const fields = [
  { label: 'Nazwa' },
  { label: 'Miejscowość' },
  { label: 'Ostatni kontakt' },
  { label: 'Województwo' },
  { label: 'Adres' },
  { label: 'Nawiązany kontakt' },
  {
    type: 'multipleFields', options: {
      fields: [
        { label: 'Telefon 1' },
        { label: 'Fax' },
        { label: 'Kod' },
        { label: 'Strona www' },
      ]
    },
  },
  { label: 'Ustawienia handlowe', type: 'multilineInput' },
  {
    label: 'Status', type: 'multipleFields', options: {
      fields: [
        { type: 'checkbox', label: 'Architekt' },
        { type: 'checkbox', label: 'Dystrybutor' },
        { type: 'checkbox', label: 'Inwestor' },
        { type: 'checkbox', label: 'Serwisant' },
        { type: 'checkbox', label: 'Inni' },
        { type: 'checkbox', label: 'Audytor' },
        { type: 'checkbox', label: 'Handel' },
        { type: 'checkbox', label: 'Projektant' },
        { type: 'checkbox', label: 'Wykonawca' },
      ]
    },
  },
];

export default fields;