import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const ProtectedRouteWrapper = ({ children }) => {
  const state = useSelector((s) => s);
  return <>{state.auth.isAuthenticated ? children : <Redirect to="/" />}</>;
};

ProtectedRouteWrapper.propTypes = {};

export default ProtectedRouteWrapper;
