import React from 'react';
import fields from './fields';
import ExpansionContainer from '../../../../common/containers/ExpansionContainer';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import DynamicForm from '../../../../common/dynamic-forms/DynamicForm';
import { Divider } from '@material-ui/core';
import PeopleDynamicForm from './PeopleDynamicForm';
import FloatingSaveBar from '../../../shared/FloatingSaveBar';

const BasicData = () => {
  return (
    <>
      <ExpansionContainer title="Dane podstawowe">
        <ColumnWrapper fullWidth>
          <DynamicForm fields={fields} />
          <Divider />
          <PeopleDynamicForm />
          <FloatingSaveBar />
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default BasicData;
