import uniqid from 'uniqid'
import React from 'react'
import EmailIcon from '@material-ui/icons/Email';
import PrimaryButton from '../components/common/buttons/PrimaryButton';

function createData(name, data, city, date, contact, actions) {
  return { name, data, city, date, contact, actions, id: uniqid() };
}

const returnActionBtn = () => <EmailIcon style={{ cursor: 'pointer' }} />
const returnIgnoreBtn = () => <PrimaryButton size="medium">Ignoruj</PrimaryButton>

const rows = [
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn(), returnIgnoreBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn(), returnIgnoreBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn(), returnIgnoreBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn(), returnIgnoreBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn(), returnIgnoreBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn(), returnIgnoreBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn(), returnIgnoreBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn(), returnIgnoreBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn(), returnIgnoreBtn()),
];

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'nazwa' },
  { id: 'data', numeric: false, disablePadding: false, label: 'dane' },
  { id: 'city', numeric: false, disablePadding: false, label: 'miasto' },
  { id: 'date', numeric: false, disablePadding: false, label: 'data' },
  { id: 'contact', numeric: false, disablePadding: false, label: 'kontakt' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'akcje' },
];

export { rows, headCells };