const productProperties = [{
  label: 'Producent',
}, {
  label: 'Kategoria',
}, {
  label: 'Urządzenie',
}, {
  label: 'Indeks',
}, {
  label: 'Opis',
},
];

const productTableProperties = [{
  label: 'Produkt',
}, {
  label: 'Produkty powiązane',
  type: 'add'
}];

export { productProperties, productTableProperties };