const fields = [
  { label: 'Nazwa projektu' },
  { label: 'Adres' },
  { label: 'Schemat' },
  { label: 'Wygrana oferta' },
  {
    type: 'multipleFields', label: 'Os. kontaktowa', options: {
      fields: [
        {},
        { label: 'Telefon' },
        { label: 'E-mail' },
      ]
    },
  },
  {
    type: 'multipleFields', label: 'Serwisant', options: {
      fields: [
        {},
        { type: 'button', options: { text: 'Zobacz' } },
        { label: 'KR' },
        { label: 'Inwestor' },
      ]
    },
  },
];

export default fields;