import uniqid from 'uniqid'

function createData(date, action, serviceMan, distance, investor, devices) {
  return { date, action, serviceMan, distance, investor, devices, id: uniqid() };
}


const rows = [
  createData('14.06.2020', 'Spawanie', '200 [km]', 'Marcin Terechaj', 'EMG 392'),
  createData('14.06.2020', 'Spawanie', '200 [km]', 'Marcin Terechaj', 'EMG 392'),
  createData('14.06.2020', 'Spawanie', '200 [km]', 'Marcin Terechaj', 'EMG 392'),
  createData('14.06.2020', 'Spawanie', '200 [km]', 'Marcin Terechaj', 'EMG 392'),
  createData('14.06.2020', 'Spawanie', '200 [km]', 'Marcin Terechaj', 'EMG 392'),
  createData('14.06.2020', 'Spawanie', '200 [km]', 'Marcin Terechaj', 'EMG 392'),
  createData('14.06.2020', 'Spawanie', '200 [km]', 'Marcin Terechaj', 'EMG 392'),
  createData('14.06.2020', 'Spawanie', '200 [km]', 'Marcin Terechaj', 'EMG 392'),
  createData('14.06.2020', 'Spawanie', '200 [km]', 'Marcin Terechaj', 'EMG 392'),
  createData('14.06.2020', 'Spawanie', '200 [km]', 'Marcin Terechaj', 'EMG 392'),
];

const headCells = [
  { id: 'date', numeric: false, disablePadding: false, label: 'data nast.' },
  { id: 'action', numeric: false, disablePadding: false, label: 'czynność' },
  { id: 'distance', numeric: false, disablePadding: false, label: 'distans km' },
  { id: 'investor', numeric: false, disablePadding: false, label: 'inwestor' },
  { id: 'devices', numeric: false, disablePadding: false, label: 'urządzenia' },
];

export { rows, headCells };