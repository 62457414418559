import React from 'react';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import ExpansionContainer from '../../../../common/containers/ExpansionContainer';
import {
  TextField,
  Select,
  MenuItem,
  Divider,
  InputAdornment,
  Input,
  Fab,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { fieldsSectionOne, fieldsSectionTwo, fieldsSectionThree } from './fields';
import TripleActionButtons from './TripleActionButtons';
import PrimaryButton from '../../../../common/buttons/PrimaryButton';
import { InputLabel, InputWrapper, Wrapper, FormWrapper } from '../../../shared/FormComponents';

const BasicData = () => {
  return (
    <>
      <ExpansionContainer title="Dane podstawowe">
        <ColumnWrapper fullWidth>
          <Wrapper fullWidth>
            {fieldsSectionOne.map((fields, i) => (
              <div
                key={`col_${i}`}
                style={i === 0 ? { marginRight: '60px' } : { marginLeft: '60px' }}>
                <FormWrapper>
                  {fields.map(({ label, type, data }, index) => (
                    <InputWrapper key={`${label}_${index}`}>
                      <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                        {label}
                      </InputLabel>
                      {type === 'select' && data ? (
                        <Select
                          style={{ backgroundColor: '#f2f2f3', width: '100%' }}
                          variant="filled"
                          labelId="label"
                          id="select"
                          value="">
                          {data.map((dataItem) => (
                            <MenuItem key={dataItem.id} value={dataItem.value}>
                              {dataItem.label}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <TextField id={label} variant="filled" />
                      )}
                    </InputWrapper>
                  ))}
                </FormWrapper>
              </div>
            ))}
          </Wrapper>
          <Divider />
          <FormWrapper>
            {fieldsSectionTwo.map(({ label, type, data, unit }) => (
              <InputWrapper key={label}>
                <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                  {label}
                </InputLabel>
                {type === 'select' && data ? (
                  <Select
                    style={{ backgroundColor: '#f2f2f3', width: '200px', marginRight: '50px' }}
                    variant="filled"
                    labelId={`${label}Label`}
                    id={`${label}Select`}
                    value="">
                    {data.map((dataItem) => (
                      <MenuItem key={dataItem.id} value={dataItem.value}>
                        {dataItem.label}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <TextField id={label} variant="filled" />
                )}
                {unit === 'percentage' ? (
                  <>
                    <Input
                      id={`${label}Input`}
                      style={{ marginRight: '50px', padding: '5px' }}
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      inputProps={{
                        'aria-label': 'procent',
                      }}
                    />
                    <Fab color="primary" aria-label="add">
                      <AddIcon />
                    </Fab>
                  </>
                ) : null}
              </InputWrapper>
            ))}
          </FormWrapper>
          <Divider />
          <FormWrapper>
            {fieldsSectionThree.map(({ label, type, data }) => (
              <InputWrapper key={label}>
                <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                  {label}
                </InputLabel>
                {type === 'select' && data ? (
                  <Select
                    style={{ backgroundColor: '#f2f2f3', width: '360px' }}
                    variant="filled"
                    labelId="label"
                    id="select"
                    value="">
                    {data.map((dataItem) => (
                      <MenuItem key={dataItem.id} value={dataItem.value}>
                        {dataItem.label}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <TextField id={label} variant="filled" />
                )}
              </InputWrapper>
            ))}
            <InputWrapper>
              <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                Dodano
              </InputLabel>
              <ColumnWrapper>
                <InputWrapper>
                  <InputLabel variant="body2">Data rozruchu</InputLabel>
                  <TextField id="from" variant="filled" />
                </InputWrapper>
                <InputWrapper>
                  <InputLabel variant="body2">Data zakończenia</InputLabel>
                  <TextField id="to" variant="filled" />
                </InputWrapper>
              </ColumnWrapper>
            </InputWrapper>
            <InputWrapper>
              <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                Schemat
              </InputLabel>
              <TripleActionButtons />
            </InputWrapper>
            <InputWrapper>
              <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                Audyt
              </InputLabel>
              <TripleActionButtons />
            </InputWrapper>
            <InputWrapper>
              <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                Zestawienie schematów
              </InputLabel>
              <PrimaryButton size="small">Podgląd</PrimaryButton>
            </InputWrapper>
            <InputWrapper>
              <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                Dodaj inne
              </InputLabel>
              <PrimaryButton size="small">Dodaj</PrimaryButton>
            </InputWrapper>
          </FormWrapper>
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default BasicData;
