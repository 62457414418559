import React from 'react';
import styled from 'styled-components';
import PrimaryButton from '../../../../common/buttons/PrimaryButton';

const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

const TripleActionButtons = () => {
  return (
    <>
      <ButtonWrapper>
        <PrimaryButton>Podgląd</PrimaryButton>
      </ButtonWrapper>
      <ButtonWrapper>
        <PrimaryButton>Pobierz</PrimaryButton>
      </ButtonWrapper>
      <ButtonWrapper>
        <PrimaryButton>Dodaj nowy</PrimaryButton>
      </ButtonWrapper>
    </>
  );
};

export default TripleActionButtons;
