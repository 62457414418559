import React from 'react'
import { TextField } from '@material-ui/core';
import MultilineInput from './form-components/MultilineInput';
import MultipleFields from './form-components/MultipleFields';
import Checkbox from './form-components/Checkbox';
import Button from './form-components/Button';
import Select from './form-components/Select';

const returnSpecificField = (type, options) => {
  switch (type) {
    case 'select':
      return <Select data={options.data} />;

    case 'button':
      return <Button text={options.text} />;

    case 'checkbox':
      return <Checkbox />

    case 'multipleFields':
      return <MultipleFields fields={options.fields} />;

    case 'multilineInput':
      return <MultilineInput />;

    default:
      return <TextField style={{ margin: '4px 0', display: 'flex' }} variant="filled" />;

  }
};

export default returnSpecificField;