import React from 'react';
import ColumnWrapper from '../../components/common/wrappers/ColumnWrapper';
import DetailsToolBar from '../../components/screen-components/companies/details/DetailsToolBar';
import NotesPanel from '../../components/unique/notes-panel/NotePanel';
import BasicData from '../../components/screen-components/companies/details/basic-data/BasicData';

const CompaniesDetailsScreen = () => {
  return (
    <>
      <ColumnWrapper>
        <DetailsToolBar />
        <NotesPanel />
        <BasicData />
      </ColumnWrapper>
    </>
  );
};

export default CompaniesDetailsScreen;
