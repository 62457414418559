import React from 'react';
import { useParams } from 'react-router';
import { productsHeadCells, productsRows } from '../../mocks/productsTableMock';
import BasicTable from '../../components/common/tables/basic/BasicTable';
import ListToolBar from '../../components/screen-components/products/list/ListToolBar';

const ProductsInCategoryScreen = () => {
  const companyId = useParams().companyId;
  return (
    <>
      <ListToolBar type="products" id={companyId} />
      <BasicTable headCells={productsHeadCells} rows={productsRows} />
    </>
  );
};

export default ProductsInCategoryScreen;
