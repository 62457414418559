import React from 'react';
import ExpansionContainer from '../../../../common/containers/ExpansionContainer';
import BasicTable from '../../../../common/tables/basic/BasicTable';
import { headCells, rows } from './tableData';
import {
  Wrapper,
  FormWrapper,
  InputWrapper,
  InputLabel,
  HorizontalSpaceDivider,
} from '../../../shared/FormComponents';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import { TextField } from '@material-ui/core';
import PrimaryButton from '../../../../common/buttons/PrimaryButton';

const OrdersData = () => {
  return (
    <>
      <ExpansionContainer title="Dostawa i zamówienia">
        <ColumnWrapper fullWidth>
          <Wrapper fullWidth>
            <FormWrapper fullWidth>
              <InputWrapper>
                <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                  Osoba do kontaktu
                </InputLabel>
                <TextField variant="filled" />
                <HorizontalSpaceDivider />
                <PrimaryButton>Dodaj następną</PrimaryButton>
              </InputWrapper>
              <InputWrapper>
                <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                  Adres dostawy
                </InputLabel>
                <TextField fullWidth multiline variant="filled" />
              </InputWrapper>
              <InputWrapper>
                <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                  Uwagi
                </InputLabel>
                <TextField fullWidth multiline variant="filled" />
              </InputWrapper>
            </FormWrapper>
          </Wrapper>
          <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
            Zamówienie Robur
          </InputLabel>
          <BasicTable headCells={headCells} rows={rows} />
          <div style={{ alignSelf: 'flex-end' }}>
            <PrimaryButton>Dodaj następny wiersz</PrimaryButton>
          </div>
          <FormWrapper fullWidth>
            <InputWrapper>
              <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                Suma
              </InputLabel>
              <TextField disabled variant="outlined" />
            </InputWrapper>
            <InputWrapper>
              <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                Suma zapłacono
              </InputLabel>
              <TextField disabled variant="outlined" />
            </InputWrapper>
            <InputWrapper>
              <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                Pozostało
              </InputLabel>
              <TextField disabled variant="outlined" />
            </InputWrapper>
          </FormWrapper>
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default OrdersData;
