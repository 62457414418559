import React from 'react';
import { Button as MaterialButton } from '@material-ui/core';

const Button = ({ text }) => {
  return (
    <>
      <MaterialButton
        variant="contained"
        style={{ color: 'white', margin: '0 10px' }}
        size="large"
        color="primary">
        {text}
      </MaterialButton>
    </>
  );
};

export default Button;
