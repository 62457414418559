import React from 'react';
import { TextField } from '@material-ui/core';

const MultilineInput = () => {
  return (
    <>
      <TextField fullWidth multiline variant="filled" />
    </>
  );
};

export default MultilineInput;
