import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography, Avatar } from '@material-ui/core';
import RowWrapper from '../../common/wrappers/RowWrapper';
import ProjectLabel from '../note-labels/ProjectLabel';
import CompanyLabel from '../note-labels/CompanyLabel';
import dateFormat from '../../../tools/dateFormat';

const NoteWrapper = styled(RowWrapper)`
  margin: 10px 0;
  width: 100%;
  justify-content: space-between;
`;

const currentDate = new Date();

const NoteItem = ({ user, note, projectLabels, comapnyLabels, date }) => {
  return (
    <>
      <NoteWrapper>
        <RowWrapper>
          <Avatar alt="Remy Sharp" src={user.image} />
          <Typography style={{ margin: '0 10px' }}>{user.name}</Typography>
          {projectLabels
            ? projectLabels.map((project, i) => (
                <ProjectLabel key={`project_${i}`} projectName={project} />
              ))
            : null}
          {comapnyLabels
            ? comapnyLabels.map((company, i) => (
                <CompanyLabel key={`comapny_${i}`} companyName={company} />
              ))
            : null}
          <Typography style={{ margin: '0 10px' }}>{note}</Typography>
        </RowWrapper>
        <RowWrapper>
          <Typography style={{ margin: '0 10px', alignSelf: 'flex-end', fontSize: '12px' }}>
            {date}
          </Typography>
        </RowWrapper>
      </NoteWrapper>
    </>
  );
};

NoteItem.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
  ),
  note: PropTypes.string,
  projectLabels: PropTypes.arrayOf(PropTypes.string),
  comapnyLabels: PropTypes.arrayOf(PropTypes.string),
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

NoteItem.defaultProps = {
  note: '',
  projectLabels: [],
  comapnyLabels: [],
  date: dateFormat.formatToDDMMYYYY(currentDate),
};

export default NoteItem;
