const indicatorReducer = (defaultState = { visible: false }, action) => {
  switch (action.type) {
    case 'SET_INDICATOR_VISIBLE':
      return {
        visible: action.payload.visible,
      };
    default:
      return defaultState;
  }
};

export default indicatorReducer;
