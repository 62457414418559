import uniqid from 'uniqid'

function createData(date, price, serviceCost, addCost, interCost, profit) {
  return { date, price, serviceCost, addCost, interCost, profit, id: uniqid() };
}

const rows = [
  createData('12.03.2020', '2000zł', '400zł', '120zł', '300zł', '700zł'),
  createData('12.03.2020', '2000zł', '400zł', '120zł', '300zł', '700zł'),
  createData('12.03.2020', '2000zł', '400zł', '120zł', '300zł', '700zł'),
  createData('12.03.2020', '2000zł', '400zł', '120zł', '300zł', '700zł'),
  createData('12.03.2020', '2000zł', '400zł', '120zł', '300zł', '700zł'),
  createData('12.03.2020', '2000zł', '400zł', '120zł', '300zł', '700zł'),
  createData('12.03.2020', '2000zł', '400zł', '120zł', '300zł', '700zł'),
  createData('12.03.2020', '2000zł', '400zł', '120zł', '300zł', '700zł'),
  createData('12.03.2020', '2000zł', '400zł', '120zł', '300zł', '700zł'),
  createData('12.03.2020', '2000zł', '400zł', '120zł', '300zł', '700zł'),
];

const headCells = [
  { id: 'date', numeric: false, disablePadding: false, label: 'data' },
  { id: 'price', numeric: false, disablePadding: false, label: 'Cena części' },
  { id: 'serviceCost', numeric: false, disablePadding: false, label: 'Koszty serwisanta' },
  { id: 'addCost', numeric: false, disablePadding: false, label: 'Koszty dodatkowe' },
  { id: 'interCost', numeric: false, disablePadding: false, label: 'Cena interwencji' },
  { id: 'profit', numeric: false, disablePadding: false, label: 'Zysk' },
];

export { rows, headCells };