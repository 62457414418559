import React from 'react';
import PropTypes from 'prop-types';
import NoteLabel from './NoteLabel';
import RowWrapper from '../../common/wrappers/RowWrapper';
import { Typography, useTheme } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';

const ProjectLabel = ({ projectName }) => {
  const theme = useTheme();
  return (
    <NoteLabel color={theme.palette.primary.main}>
      <RowWrapper>
        <AssignmentIcon style={{ fontSize: 14 }} />
        <Typography style={{ marginLeft: '6px' }} color="inherit" variant="body2">
          {projectName}
        </Typography>
      </RowWrapper>
    </NoteLabel>
  );
};

ProjectLabel.propTypes = {
  projectName: PropTypes.string,
};

ProjectLabel.defaultProps = {
  projectName: 'Projekt',
};

export default ProjectLabel;
