import React from 'react';
import { headCells, rows } from '../../mocks/offersTableMock';
import routerHistory from '../../settings/routerHistory';
import BasicTable from '../../components/common/tables/basic/BasicTable';
import ListToolBar from '../../components/screen-components/offers/list/ListToolBar';

const OffersScreen = () => (
  <>
    <ListToolBar />
    <BasicTable
      headCells={headCells}
      rows={rows}
      onRowClick={({ id }) => routerHistory.push(`/offers/${id}`)}
    />
  </>
);

export default OffersScreen;
