import React from 'react';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { TextField, Button, Checkbox, Typography } from '@material-ui/core';
import { FormWrapper } from '../../../shared/FormComponents';
import RowWrapper from '../../../../common/wrappers/RowWrapper';

const inputMargin = { marginRight: 10 };

const personFieldWrapperStyle = { padding: '10px 0', display: 'flex', alignItems: 'center' };

const PeopleDynamicForm = () => {
  const formik = useFormik({
    initialValues: {
      people: [{ name: '', job: '', phone: '', email: '' }],
    },
  });

  return (
    <>
      <FormikProvider value={formik}>
        <FieldArray
          name="people"
          render={(arrayHelpers) => (
            <FormWrapper>
              {formik.values.people && formik.values.people.length > 0
                ? formik.values.people.map((person, index) => (
                    <div style={personFieldWrapperStyle} key={index}>
                      <TextField
                        variant="filled"
                        placeholder="Osoba"
                        name={`people.${index}.name`}
                        style={inputMargin}
                      />
                      <TextField
                        variant="filled"
                        placeholder="Stanowisko"
                        name={`people.${index}.job`}
                        style={inputMargin}
                      />
                      <TextField
                        variant="filled"
                        placeholder="Tel. Komórkowy"
                        name={`people.${index}.phone`}
                        style={inputMargin}
                      />
                      <TextField
                        variant="filled"
                        placeholder="E-mail"
                        name={`people.${index}.email`}
                        style={inputMargin}
                      />
                      <RowWrapper>
                        <Typography variant="body1">Mailing</Typography>
                        <Checkbox />
                      </RowWrapper>
                      <Button
                        variant="contained"
                        style={{ color: 'white' }}
                        size="large"
                        color="primary"
                        onClick={() => arrayHelpers.remove(index)}>
                        -
                      </Button>
                    </div>
                  ))
                : null}
              <Button
                variant="contained"
                style={{ color: 'white', alignSelf: 'flex-start' }}
                size="large"
                color="primary"
                onClick={() => arrayHelpers.push('')}>
                Dodaj więcej kontaktów
              </Button>
            </FormWrapper>
          )}
        />
      </FormikProvider>
    </>
  );
};

export default PeopleDynamicForm;
