import React from 'react';
import { sectionOne, sectionTwo } from './fields';
import ExpansionContainer from '../../../../common/containers/ExpansionContainer';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import DynamicForm from '../../../../common/dynamic-forms/DynamicForm';
import { Divider } from '@material-ui/core';

const OfferData = () => {
  return (
    <>
      <ExpansionContainer title="Dane oferty">
        <ColumnWrapper fullWidth>
          <DynamicForm fields={sectionOne} />
          <Divider />
          <DynamicForm fields={sectionTwo} />
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default OfferData;
