import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const ItemContainer = styled.div`
  background: #fbfbfb;
  padding: 5px 10px;
  display: flex;
  align-items: center;
`;

const Text = styled(Typography)`
  margin: 0 10px;
`;

export { ItemContainer, Text };