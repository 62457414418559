import React from 'react';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import ExpansionContainer from '../../../../common/containers/ExpansionContainer';
import { TextField, Divider, Card } from '@material-ui/core';
import {
  InputLabel,
  InputWrapper,
  Wrapper,
  FormWrapper,
  ColumnWrapperWithRightSpace,
} from '../../../shared/FormComponents';
import { productProperties, productTableProperties } from './fields';
import PrimaryButton from '../../../../common/buttons/PrimaryButton';

const ProjectDesignData = () => {
  return (
    <>
      <ExpansionContainer title="Co zaprojektowano">
        <ColumnWrapper fullWidth>
          <Wrapper fullWidth>
            <FormWrapper>
              <InputWrapper>
                <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                  Nazwa wariantu
                </InputLabel>
                <TextField variant="filled" />
              </InputWrapper>
              <InputWrapper>
                <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                  Produkt
                </InputLabel>
                {productProperties.map(({ label }) => (
                  <ColumnWrapperWithRightSpace key={label}>
                    <InputLabel variant="body2">{label}</InputLabel>
                    <TextField id={label} variant="filled" />
                  </ColumnWrapperWithRightSpace>
                ))}
                <PrimaryButton size="large">Dodaj</PrimaryButton>
              </InputWrapper>
            </FormWrapper>
          </Wrapper>
          <Divider />
          <Wrapper fullWidth>
            <FormWrapper fullWidth>
              <Card style={{ padding: '25px' }} variant="outlined">
                {productTableProperties.map((row) => (
                  <InputWrapper key={row.label}>
                    <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                      {row.label}
                    </InputLabel>
                    {productProperties.map(({ label }) => (
                      <ColumnWrapperWithRightSpace key={label}>
                        <InputLabel variant="body2">{label}</InputLabel>
                        <TextField variant="filled" />
                      </ColumnWrapperWithRightSpace>
                    ))}
                    {row.type === 'add' ? (
                      <div style={{ minWidth: '180px' }}>
                        <PrimaryButton size="large">Dodaj następny</PrimaryButton>
                      </div>
                    ) : null}
                  </InputWrapper>
                ))}
              </Card>
            </FormWrapper>
          </Wrapper>
          <div style={{ maxWidth: '280px' }}>
            <PrimaryButton size="large">Dodaj nowy produkt</PrimaryButton>
          </div>
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default ProjectDesignData;
