import React from 'react';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import fields from './fields';
import ExpansionContainer from '../../../../common/containers/ExpansionContainer';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import DynamicForm from '../../../../common/dynamic-forms/DynamicForm';
import { Checkbox, TextField, Typography, Button } from '@material-ui/core';
import { FormWrapper } from '../../../shared/FormComponents';
import RowWrapper from '../../../../common/wrappers/RowWrapper';

const inputMargin = { marginRight: 10 };

const personFieldWrapperStyle = { padding: '10px 0', display: 'flex', alignItems: 'center' };

const MarketingCompanyForm = () => {
  const formik = useFormik({
    initialValues: {
      contacts: [{ name: '', pos: '', phone: '', email: '', mailing: '' }],
    },
  });
  return (
    <>
      <ExpansionContainer title="Formularz">
        <ColumnWrapper fullWidth>
          <FormikProvider value={formik}>
            <FieldArray
              name="contacts"
              render={(arrayHelpers) => (
                <FormWrapper>
                  {formik.values.contacts && formik.values.contacts.length > 0
                    ? formik.values.contacts.map((person, index) => (
                        <div style={personFieldWrapperStyle} key={index}>
                          <TextField
                            variant="filled"
                            placeholder="Osoba"
                            name={`contacts.${index}.name`}
                            style={inputMargin}
                          />
                          <TextField
                            variant="filled"
                            placeholder="Stanowisko"
                            name={`contacts.${index}.job`}
                            style={inputMargin}
                          />
                          <TextField
                            variant="filled"
                            placeholder="Tel. Komórkowy"
                            name={`contacts.${index}.phone`}
                            style={inputMargin}
                          />
                          <TextField
                            variant="filled"
                            placeholder="E-mail"
                            name={`contacts.${index}.email`}
                            style={inputMargin}
                          />
                          <RowWrapper>
                            <Typography variant="body1">Mailing</Typography>
                            <Checkbox />
                          </RowWrapper>
                          <Button
                            variant="contained"
                            style={{ color: 'white' }}
                            size="large"
                            color="primary"
                            onClick={() => arrayHelpers.remove(index)}>
                            -
                          </Button>
                        </div>
                      ))
                    : null}
                  <Button
                    variant="contained"
                    style={{ color: 'white', alignSelf: 'flex-start' }}
                    size="large"
                    color="primary"
                    onClick={() => arrayHelpers.push('')}>
                    Dodaj więcej kontaktów
                  </Button>
                </FormWrapper>
              )}
            />
          </FormikProvider>
          <DynamicForm fields={fields} />
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default MarketingCompanyForm;
