import React from 'react';
import ListToolBar from '../../../components/screen-components/marketing/list/ListToolBar';
import { headCells, rows } from '../../../mocks/marketingMailTableMock';
import BasicTable from '../../../components/common/tables/basic/BasicTable';
import routerHistory from '../../../settings/routerHistory';

const MarketingScreen = () => (
  <>
    <ListToolBar
      onClick={() => routerHistory.push('/marketing/email-list/import')}
      buttonText="Importuj"
    />
    <BasicTable headCells={headCells} rows={rows} />
  </>
);

export default MarketingScreen;
