import React from 'react';
import { Typography } from '@material-ui/core';
import ActionContainer from '../../components/unique/action-container/ActionContainer';

const AdministrationConfigurationScreeen = () => (
  <>
    <Typography>Ustawienia systemu CRM</Typography>
    <ActionContainer
      header="Edytuj słowniki"
      buttonText="Edytuj"
      label="Wprowadź wartości, którymi można uzupełnić formularze"
    />
    <ActionContainer
      header="Ustawienia importu (Marketing)"
      buttonText="Zobacz"
      label="Wprowadź dane do zintegrowania usług Marketing Automation"
    />
  </>
);

export default AdministrationConfigurationScreeen;
