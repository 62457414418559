import React from 'react';
import SearchField from '../../../common/inputs/search-field/SearchField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { PROJECT_TYPES, PROJECT_USERS } from '../../../../mocks/projectsToolBarMock';
import { Wrapper, InputWrapper } from '../../shared/ListToolBarComponents';
import RowWrapper from '../../../common/wrappers/RowWrapper';
import { Checkbox, Typography } from '@material-ui/core';

const ListToolBar = () => {
  return (
    <>
      <Wrapper>
        <RowWrapper fullWidth>
          <Checkbox />
          <Typography variant="body1">Tylko moje zadania</Typography>
        </RowWrapper>
        <InputWrapper>
          <SearchField />
        </InputWrapper>
        <InputWrapper>
          <Select
            style={{ backgroundColor: '#f2f2f3' }}
            variant="filled"
            IconComponent={ArrowDropDownIcon}
            labelId="label"
            id="select"
            value={PROJECT_TYPES[0].value}>
            {PROJECT_TYPES.map(({ id, label, value }) => (
              <MenuItem key={id} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </InputWrapper>
        <InputWrapper>
          <Select
            style={{ backgroundColor: '#f2f2f3' }}
            variant="filled"
            IconComponent={ArrowDropDownIcon}
            labelId="label"
            id="select"
            value={PROJECT_TYPES[0].value}>
            {PROJECT_TYPES.map(({ id, label, value }) => (
              <MenuItem key={id} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </InputWrapper>
        <InputWrapper>
          <Select
            style={{ backgroundColor: '#f2f2f3' }}
            variant="filled"
            IconComponent={ArrowDropDownIcon}
            labelId="label"
            id="select"
            value={PROJECT_USERS[0].value}>
            {PROJECT_USERS.map(({ id, label, value }) => (
              <MenuItem key={id} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </InputWrapper>
      </Wrapper>
    </>
  );
};

export default ListToolBar;
