import React from 'react';
import fields from './fields';
import ExpansionContainer from '../../../../common/containers/ExpansionContainer';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import DynamicForm from '../../../../common/dynamic-forms/DynamicForm';

const Warranty = () => {
  return (
    <>
      <ExpansionContainer title="Gwarancja i przeglądy">
        <ColumnWrapper fullWidth>
          <DynamicForm fields={fields} />
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default Warranty;
