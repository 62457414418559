import uniqid from 'uniqid'
import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import styled from 'styled-components';
import EmailIcon from '@material-ui/icons/Email';

function createData(name, data, role, date, contact) {
  return { name, data, role, date, contact, id: uniqid() };
}

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 8px;
  }
`

const userImg = 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'

const returnUser = () => <UserInfo><Avatar alt="Remy Sharp" src={userImg} /><p>Piotr</p></UserInfo>
const returnActionBtn = () => <EmailIcon style={{ cursor: 'pointer' }} />

const rows = [
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
  createData(returnUser(), 'dane', 'Administrator', '21.02.2020', returnActionBtn()),
];

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'nazwa' },
  { id: 'data', numeric: false, disablePadding: false, label: 'dane' },
  { id: 'role', numeric: false, disablePadding: false, label: 'uprawnienia' },
  { id: 'date', numeric: false, disablePadding: false, label: 'data' },
  { id: 'contact', numeric: false, disablePadding: false, label: 'kontakt' },
];

export { rows, headCells };