import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RowWrapper from '../../../common/wrappers/RowWrapper';
import { Typography } from '@material-ui/core';

const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: ${(props) => (props.active ? '#a9ee91' : 'red')};
  margin-right: 6px;
`;

const ActiveStatus = ({ isActive }) => {
  return (
    <>
      <RowWrapper>
        <Circle active={isActive} />
        <Typography variant="body2">{isActive ? 'aktywny' : 'nieaktywny'}</Typography>
      </RowWrapper>
    </>
  );
};

ActiveStatus.propTypes = {
  isActive: PropTypes.bool,
};

ActiveStatus.defaultProps = {
  isActive: true,
};

export default ActiveStatus;
