import React from 'react';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import ExpansionContainer from '../../../../common/containers/ExpansionContainer';
import { TextField, Divider, InputAdornment, Input, Checkbox } from '@material-ui/core';
import {
  InputLabel,
  InputWrapper,
  FormWrapper,
  ColumnWrapperWithRightSpace,
  RowWrapperWithRightSpace,
} from '../../../shared/FormComponents';
import { currency, commissionTypes, persons, others } from './fields';

const CommissionsData = () => {
  return (
    <>
      <ExpansionContainer title="Prowizje">
        <ColumnWrapper fullWidth>
          <FormWrapper>
            {currency.map(({ label }) => (
              <InputWrapper key={label}>
                <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                  {label}
                </InputLabel>
                <TextField variant="filled" />
              </InputWrapper>
            ))}
          </FormWrapper>
          <Divider />
          <FormWrapper>
            {commissionTypes.map(({ label, fields }) => (
              <InputWrapper key={label}>
                <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                  {label}
                </InputLabel>
                {fields.map(({ unitType }, i) => (
                  <Input
                    key={`field${i}`}
                    style={{ marginRight: '50px', padding: '5px' }}
                    endAdornment={<InputAdornment position="end">{unitType}</InputAdornment>}
                  />
                ))}
              </InputWrapper>
            ))}
          </FormWrapper>
          <Divider />
          <FormWrapper>
            {persons.map(({ label }) => (
              <InputWrapper key={label}>
                <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                  {label}
                </InputLabel>
                <ColumnWrapperWithRightSpace>
                  <TextField variant="filled" />
                </ColumnWrapperWithRightSpace>
                <ColumnWrapperWithRightSpace>
                  <TextField variant="filled" />
                </ColumnWrapperWithRightSpace>
                <ColumnWrapperWithRightSpace>
                  <TextField variant="filled" />
                </ColumnWrapperWithRightSpace>
              </InputWrapper>
            ))}
          </FormWrapper>
          <Divider />
          <FormWrapper>
            {others.map(({ label, type }) => (
              <InputWrapper key={label}>
                <InputLabel style={{ fontWeight: 'bold' }} variant="subtitle2">
                  {label}
                </InputLabel>
                {type === 'multiple' ? (
                  <>
                    <RowWrapperWithRightSpace>
                      <TextField variant="filled" />
                      <Checkbox />
                    </RowWrapperWithRightSpace>
                    <RowWrapperWithRightSpace>
                      <TextField variant="filled" />
                      <Checkbox />
                    </RowWrapperWithRightSpace>
                    <RowWrapperWithRightSpace>
                      <TextField variant="filled" />
                      <Checkbox />
                    </RowWrapperWithRightSpace>
                  </>
                ) : (
                  <TextField multiline fullWidth variant="filled" />
                )}
              </InputWrapper>
            ))}
          </FormWrapper>
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default CommissionsData;
