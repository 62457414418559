import { Collapse } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import React, { Fragment, useEffect, useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import logo from '../../../assets/logo/gazuno_logo.svg';
import menuItems from '../../../constants/menu/menuItems';
import routerHistory from '../../../settings/routerHistory';
import ColumnWrapper from '../wrappers/ColumnWrapper';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  avatarWrapper: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    padding: '10px',
  },
  avatarText: {
    margin: '0 10px',
  },
  logoStyle: {
    marginBottom: '40px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const SideMenu = ({ children }) => {
  const [list, setList] = useState(menuItems);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const location = useLocation();

  useEffect(() => {
    const filteredList = list.map((item) => {
      let isOpen = false;
      if (item.subRoutes)
        item.subRoutes.map((i) => {
          if (location.pathname.includes(i.routeName)) {
            setIsCollapsed(false);
            isOpen = true;
          }
          return i;
        });
      return { ...item, isOpen };
    });
    setList(filteredList);
    // eslint-disable-next-line
  }, []);

  const [user] = useState({
    name: 'Piotr',
    image:
      'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
  });

  const {
    root,
    drawer,
    drawerPaper,
    toolbar,
    avatarWrapper,
    avatarText,
    logoStyle,
    content,
  } = useStyles();

  const closeAllLists = () => {
    if (!isCollapsed) {
      const filteredList = list.map((item) => {
        return { ...item, isOpen: false };
      });
      setList(filteredList);
    }
  };

  const handleCollapse = (id) => {
    const filteredList = list.map((item) => {
      if (item.id === id) {
        setIsCollapsed(item.isOpen);
        return { ...item, isOpen: !item.isOpen };
      }
      return item;
    });
    setList(filteredList);
  };

  return (
    <div className={root}>
      <CssBaseline />
      {!(location.pathname === '/') ? (
        <Drawer
          className={drawer}
          variant="permanent"
          classes={{
            paper: drawerPaper,
          }}
          anchor="left">
          <div className={toolbar} />
          <img className={logoStyle} src={logo} alt="logo" />
          <div
            onClick={() => routerHistory.push('/main')}
            className={avatarWrapper}>
            <Avatar alt="Remy Sharp" src={user.image} />
            <Typography className={avatarText} paragraph>
              {user.name}
            </Typography>
          </div>
          <Divider />
          <List>
            {list.map(({ id, icon, label, routeName, subRoutes, isOpen }) => (
              <Fragment key={id}>
                <ListItem
                  activeStyle={{
                    background: '#e2e2e2',
                  }}
                  button
                  component={RouterLink}
                  to={routeName}
                  onClick={() =>
                    subRoutes ? handleCollapse(id) : closeAllLists()
                  }>
                  <ListItemIcon>{icon()}</ListItemIcon>
                  <ListItemText primary={label} />
                </ListItem>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ColumnWrapper>
                      {subRoutes
                        ? subRoutes.map((subRoute) => (
                            <ListItem
                              activeStyle={{
                                background: '#b1b1b1',
                              }}
                              button
                              component={RouterLink}
                              to={subRoute.routeName}
                              key={subRoute.routeName}>
                              <ListItemIcon>
                                <LabelImportantIcon />
                              </ListItemIcon>
                              <Typography variant="body2">
                                {subRoute.label}
                              </Typography>
                            </ListItem>
                          ))
                        : null}
                    </ColumnWrapper>
                  </List>
                </Collapse>
              </Fragment>
            ))}
          </List>
        </Drawer>
      ) : null}
      <main className={content}>{children}</main>
    </div>
  );
};

export default SideMenu;
