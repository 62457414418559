import React from 'react'
import uniqid from 'uniqid'
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';

function createcompanyData(name, added, actions) {
  return { name, added, actions, id: uniqid() };
}

function createCategoriesData(name, added, actions) {
  return { name, added, actions, id: uniqid() };
}

function createProductsData(name, index, added, price, action) {
  return { name, index, added, price, action, id: uniqid() };
}

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 8px;
  }
`

const userImg = 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'

const returnUser = () => <UserInfo><Avatar alt="Remy Sharp" src={userImg} /><p>Piotr</p></UserInfo>
const returnActionBtn = (text) => <Button variant="contained" style={{ color: 'white' }} size="large" color="primary">{text}</Button>


const companyRows = [
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
  createcompanyData('Firma A', returnUser(), returnActionBtn('Zobacz kategorie')),
];

const companyHeadCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'nazwa' },
  { id: 'added', numeric: false, disablePadding: false, label: 'dodał' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'akcje' },
];

const categoriesRows = [
  createCategoriesData('Firma kategorii', returnUser(), returnActionBtn('Zobacz produkty')),
  createCategoriesData('Firma kategorii', returnUser(), returnActionBtn('Zobacz produkty')),
  createCategoriesData('Firma kategorii', returnUser(), returnActionBtn('Zobacz produkty')),
  createCategoriesData('Firma kategorii', returnUser(), returnActionBtn('Zobacz produkty')),
  createCategoriesData('Firma kategorii', returnUser(), returnActionBtn('Zobacz produkty')),
  createCategoriesData('Firma kategorii', returnUser(), returnActionBtn('Zobacz produkty')),
  createCategoriesData('Firma kategorii', returnUser(), returnActionBtn('Zobacz produkty')),
  createCategoriesData('Firma kategorii', returnUser(), returnActionBtn('Zobacz produkty')),
  createCategoriesData('Firma kategorii', returnUser(), returnActionBtn('Zobacz produkty')),
  createCategoriesData('Firma kategorii', returnUser(), returnActionBtn('Zobacz produkty')),
];

const categoriesHeadCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'nazwa' },
  { id: 'added', numeric: false, disablePadding: false, label: 'dodał' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'akcje' },
];

const productsRows = [
  createProductsData('Nazwa produktu', 'EAN82132143', returnUser(), '3250 PLN', returnActionBtn('Zobacz części (3)')),
  createProductsData('Nazwa produktu', 'EAN82132143', returnUser(), '3250 PLN', returnActionBtn('Zobacz części (3)')),
  createProductsData('Nazwa produktu', 'EAN82132143', returnUser(), '3250 PLN', returnActionBtn('Zobacz części (3)')),
  createProductsData('Nazwa produktu', 'EAN82132143', returnUser(), '3250 PLN', returnActionBtn('Zobacz części (3)')),
  createProductsData('Nazwa produktu', 'EAN82132143', returnUser(), '3250 PLN', returnActionBtn('Zobacz części (3)')),
  createProductsData('Nazwa produktu', 'EAN82132143', returnUser(), '3250 PLN', returnActionBtn('Zobacz części (3)')),
  createProductsData('Nazwa produktu', 'EAN82132143', returnUser(), '3250 PLN', returnActionBtn('Zobacz części (3)')),
  createProductsData('Nazwa produktu', 'EAN82132143', returnUser(), '3250 PLN', returnActionBtn('Zobacz części (3)')),
  createProductsData('Nazwa produktu', 'EAN82132143', returnUser(), '3250 PLN', returnActionBtn('Zobacz części (3)')),
  createProductsData('Nazwa produktu', 'EAN82132143', returnUser(), '3250 PLN', returnActionBtn('Zobacz części (3)')),
  createProductsData('Nazwa produktu', 'EAN82132143', returnUser(), '3250 PLN', returnActionBtn('Zobacz części (3)')),
  createProductsData('Nazwa produktu', 'EAN82132143', returnUser(), '3250 PLN', returnActionBtn('Zobacz części (3)')),
  createProductsData('Nazwa produktu', 'EAN82132143', returnUser(), '3250 PLN', returnActionBtn('Zobacz części (3)')),
  createProductsData('Nazwa produktu', 'EAN82132143', returnUser(), '3250 PLN', returnActionBtn('Zobacz części (3)')),
  createProductsData('Nazwa produktu', 'EAN82132143', returnUser(), '3250 PLN', returnActionBtn('Zobacz części (3)')),
];

const productsHeadCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'nazwa' },
  { id: 'index', numeric: false, disablePadding: false, label: 'indeks' },
  { id: 'added', numeric: false, disablePadding: false, label: 'dodał' },
  { id: 'price', numeric: false, disablePadding: false, label: 'cena netto/szt' },
  { id: 'action', numeric: false, disablePadding: false, label: 'akcje' },
];

export { companyRows, companyHeadCells, categoriesRows, categoriesHeadCells, productsHeadCells, productsRows };