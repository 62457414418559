import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import apiCall from '../../../tools/api/apiCall';
import Portal from '../portal/Portal';

let dispatch = null;

const Indicator = (props) => {
  const state = useSelector((state) => state);

  dispatch = useDispatch();
  return (
    <>
      {state.indicator.visible ? (
        <Portal>
          <div
            style={{
              position: 'fixed',
              width: '100%',
              top: 0,
              left: 0,
              zIndex: 999,
            }}>
            <LinearProgress />
          </div>
        </Portal>
      ) : null}
    </>
  );
};

Indicator.propTypes = {};

const getCall = async (url, headers) =>
  await apiCall.getCall(dispatch, url, headers);

const postCall = async (url, data, headers) =>
  await apiCall.postCall(dispatch, url, data, headers);

const putCall = async (url, data, headers) =>
  await apiCall.putCall(dispatch, url, data, headers);

const deleteCall = async (url, data, headers) =>
  await apiCall.deleteCall(dispatch, url, data, headers);

export { Indicator, getCall, postCall, putCall, deleteCall };
