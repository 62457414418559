import React from 'react';
import ColumnWrapper from '../../components/common/wrappers/ColumnWrapper';
import NotesPanel from '../../components/unique/notes-panel/NotePanel';
import ProjectDesignData from '../../components/screen-components/projects/details/project-design-data/ProjectDesignData';
import OffersData from '../../components/screen-components/projects/details/offers-data/OffersData';
import OrdersData from '../../components/screen-components/projects/details/orders-data/OrdersData';
import CommissionsData from '../../components/screen-components/projects/details/commissions-data/CommissionsData';
import DetailsToolBar from '../../components/screen-components/projects/details/DetailsToolBar';
import FloatingSaveBar from '../../components/screen-components/shared/FloatingSaveBar';
import AutomationData from '../../components/screen-components/projects/details/automotion-data/AutomationData';
import BasicData from '../../components/screen-components/projects/details/basic-data/BasicData';

const ProjectDetailsScreen = () => {
  return (
    <>
      <ColumnWrapper>
        <DetailsToolBar />
        <NotesPanel />
        <BasicData />
        <ProjectDesignData />
        <AutomationData />
        <OffersData />
        <OrdersData />
        <CommissionsData />
        <FloatingSaveBar />
      </ColumnWrapper>
    </>
  );
};

export default ProjectDetailsScreen;
