import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select as MaterialSelect, MenuItem } from '@material-ui/core';

const Select = ({ data, onSelect }) => {
  const [selected, setSelected] = useState('');

  const handleSelect = (event) => {
    setSelected(event.target.value);
    onSelect(event.target.value);
  };

  return (
    <>
      <MaterialSelect
        style={{ backgroundColor: '#f2f2f3', width: '100%' }}
        variant="filled"
        onChange={(event) => handleSelect(event)}
        value={selected}>
        {data.map((dataItem) => (
          <MenuItem key={dataItem.id} value={dataItem}>
            {dataItem.label}
          </MenuItem>
        ))}
      </MaterialSelect>
    </>
  );
};

Select.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ),
  onSelect: PropTypes.func,
};

Select.defaultProps = {
  data: [],
  onSelect: () => {},
};

export default Select;
