import React from 'react';
import ListToolBar from '../../../components/screen-components/marketing/add/ListToolBar';
import Notes from '../../../components/screen-components/marketing/add/notes/Notes';
import MarketingCompanyForm from '../../../components/screen-components/marketing/add/form/MarketingCompanyForm';

const MarketingAddCompanyScreen = () => (
  <>
    <ListToolBar />
    <MarketingCompanyForm />
    <Notes />
  </>
);

export default MarketingAddCompanyScreen;
