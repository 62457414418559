import React from 'react';
import ColumnWrapper from '../components/common/wrappers/ColumnWrapper';
import RowWrapper from '../components/common/wrappers/RowWrapper';
import EventsBoard from '../components/screen-components/main/events-board/EventsBoard';
import ProjectsBoard from '../components/screen-components/main/projects-board/ProjectsBoard';
import TaskBoard from '../components/screen-components/main/task-board/TaskBoard';
import ToolBar from '../components/screen-components/main/tool-bar/ToolBar';

const MainScreen = () => (
  <>
    <ToolBar />
    <RowWrapper style={{ alignItems: 'flex-start' }} fullWidth>
      <ColumnWrapper fullWidth>
        <TaskBoard />
        <ProjectsBoard />
      </ColumnWrapper>
      <EventsBoard />
    </RowWrapper>
  </>
);

export default MainScreen;
