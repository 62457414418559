import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createStore } from 'redux';
import { Indicator } from './components/common/indicator/Indicator';
import SideMenu from './components/common/navigation/SideMenu';
import ProtectedRouteWrapper from './components/common/wrappers/ProtectedRouteWrapper';
import reducer from './redux/reducers/reducer';
import AdministrationUserDetailsScreen from './screens/administration/AdministartionUsersDetailsScreen';
import AdministrationConfigurationScreeen from './screens/administration/AdministrationConfigurationScreen';
import AdministrationScreen from './screens/administration/AdministrationScreen';
import CalendarScreen from './screens/calendar/CalendarScreen';
import SlotScreen from './screens/calendar/SlotScreen';
import CompaniesDetailsScreen from './screens/companies/CompaniesDetailsScreen';
import CompaniesScreen from './screens/companies/CompaniesScreen';
import DocumentsScreen from './screens/documents/DocumentsScreen';
import LoginScreen from './screens/login/LoginScreen';
import MainScreen from './screens/MainScreen';
import MarketingAddCompanyScreen from './screens/marketing/companies/MarketingAddCompanyScreen';
import MarketingCompaniesScreen from './screens/marketing/companies/MarketingCompaniesScreen';
import ImportContactListScreen from './screens/marketing/emails/ImportContactsScreen';
import ImportMailListScreen from './screens/marketing/emails/ImportMailListScreen';
import MarketingScreen from './screens/marketing/emails/MarketingScreen';
import OffersDetailsScreen from './screens/offers/OffersDetailsScreen';
import OffersScreen from './screens/offers/OffersScreen';
import ProductsCategoryScreen from './screens/products/ProductsCatgoryScreen';
import ProductsInCategoryScreen from './screens/products/ProductsInCategoryScreen';
import ProductsScreen from './screens/products/ProductsScreen';
import AutomotionScreen from './screens/projects/AutomationScreen';
import ProjectDetailsScreen from './screens/projects/ProjectDetailsScreen';
import ProjectsScreen from './screens/projects/ProjectsScreen';
import ServiceScreen from './screens/projects/ServiceScreen';
import ReportsScreen from './screens/reports/ReportsScreen';
import TasksScreen from './screens/tasks/TasksScreen';
import history from './settings/routerHistory';
import theme from './settings/theme';

const store = createStore(reducer);

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ToastContainer />
          <Indicator />
          <Router history={history}>
            <Switch>
              <SideMenu>
                <ProtectedRouteWrapper>
                  <Route exact path="/projects">
                    <ProjectsScreen />
                  </Route>
                  <Route exact path="/projects/:id">
                    <ProjectDetailsScreen />
                  </Route>
                  <Route path="/projects/:id/automation">
                    <AutomotionScreen />
                  </Route>
                  <Route path="/projects/:id/service">
                    <ServiceScreen />
                  </Route>
                  <Route exact path="/companies">
                    <CompaniesScreen />
                  </Route>
                  <Route path="/companies/:id">
                    <CompaniesDetailsScreen />
                  </Route>
                  <Route path="/tasks">
                    <TasksScreen />
                  </Route>
                  <Route exact path="/offers">
                    <OffersScreen />
                  </Route>
                  <Route path="/offers/:id">
                    <OffersDetailsScreen />
                  </Route>
                  <Route exact path="/products">
                    <ProductsScreen />
                  </Route>
                  <Route exact path="/products/:id/categories">
                    <ProductsCategoryScreen />
                  </Route>
                  <Route path="/products/:companyId/categories/:categoryId">
                    <ProductsInCategoryScreen />
                  </Route>
                  <Route path="/documents">
                    <DocumentsScreen />
                  </Route>
                  <Route exact path="/calendar">
                    <CalendarScreen />
                  </Route>
                  <Route exact path="/calendar/add-slot">
                    <SlotScreen />
                  </Route>
                  <Route exact path="/marketing/email-list">
                    <MarketingScreen />
                  </Route>
                  <Route exact path="/marketing/email-list/import">
                    <ImportMailListScreen />
                  </Route>
                  <Route exact path="/marketing/email-list/import/list">
                    <ImportContactListScreen />
                  </Route>
                  <Route exact path="/marketing/companies">
                    <MarketingCompaniesScreen />
                  </Route>
                  <Route exact path="/marketing/companies/add">
                    <MarketingAddCompanyScreen />
                  </Route>
                  <Route path="/reports">
                    <ReportsScreen />
                  </Route>
                  <Route exact path="/administration/users">
                    <AdministrationScreen />
                  </Route>
                  <Route path="/administration/configuration">
                    <AdministrationConfigurationScreeen />
                  </Route>
                  <Route exact path="/administration/users/edit">
                    <AdministrationUserDetailsScreen />
                  </Route>
                  <Route path="/administration/users/edit/:id">
                    <AdministrationUserDetailsScreen />
                  </Route>
                  <Route exact path="/main">
                    <MainScreen />
                  </Route>
                </ProtectedRouteWrapper>
                <Route exact path="/">
                  <LoginScreen />
                </Route>
              </SideMenu>
            </Switch>
          </Router>
        </Provider>
      </ThemeProvider>
    </>
  );
};

export default App;
