import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Button } from '@material-ui/core';
import styled from 'styled-components';
import RowWrapper from '../../common/wrappers/RowWrapper';
import ColumnWrapper from '../../common/wrappers/ColumnWrapper';

const PaperWrapper = styled.div`
  margin-top: 15px;
`;
const PaperContainer = styled(RowWrapper)`
  justify-content: space-between;
  padding: 25px;
`;

const ActionContainer = ({ label, buttonText, header, onClick }) => (
  <PaperWrapper>
    <Paper variant="outlined" square>
      <PaperContainer>
        <ColumnWrapper>
          {header ? (
            <Typography style={{ fontWeight: 'bold' }} variant="body1">
              {header}
            </Typography>
          ) : null}
          {label ? <Typography variant="body2">{label}</Typography> : null}
        </ColumnWrapper>
        <Button onClick={onClick} style={{ color: 'white' }} variant="contained" color="primary">
          {buttonText}
        </Button>
      </PaperContainer>
    </Paper>
  </PaperWrapper>
);

ActionContainer.propTypes = {
  label: PropTypes.string,
  buttonText: PropTypes.string,
  header: PropTypes.string,
  onClick: PropTypes.func,
};

ActionContainer.defaultProps = {
  label: '',
  buttonText: 'Generuj',
  header: '',
  onClick: () => {},
};

export default ActionContainer;
