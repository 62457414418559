const PROJECT_TYPES = [{
  id: 1,
  label: 'Typ numer 1',
  value: '1'
}, {
  id: 2,
  label: 'Typ numer 2',
  value: '2'
}, {
  id: 3,
  label: 'Typ numer 3',
  value: '3'
}];

const PROJECT_USERS = [{
  id: 1,
  label: 'Piotr',
  value: 'Piotr'
}, {
  id: 2,
  label: 'Anna',
  value: 'Anna'
}, {
  id: 3,
  label: 'Tomasz',
  value: 'Tomasz'
}];


const PROJECT_DATE = [{
  id: 1,
  label: '10.02.20',
  value: '10.02.20'
}, {
  id: 2,
  label: '11.02.20',
  value: '11.02.20'
}, {
  id: 3,
  label: '12.02.20',
  value: '12.02.20'
}];


export { PROJECT_TYPES, PROJECT_USERS, PROJECT_DATE }