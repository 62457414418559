import React from 'react';
import { headCells, rows } from '../../mocks/companiesTableMock';
import routerHistory from '../../settings/routerHistory';
import BasicTable from '../../components/common/tables/basic/BasicTable';
import ListToolBar from '../../components/screen-components/companies/list/ListToolBar';

const CompaniesScreen = () => (
  <>
    <ListToolBar />
    <BasicTable
      headCells={headCells}
      rows={rows}
      onRowClick={({ id }) => routerHistory.push(`/companies/${id}`)}
    />
  </>
);

export default CompaniesScreen;
