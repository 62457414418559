import React from 'react';
import PropTypes from 'prop-types';
import SearchField from '../../../common/inputs/search-field/SearchField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { PROJECT_TYPES } from '../../../../mocks/projectsToolBarMock';
import { Wrapper, InputWrapper } from '../../shared/ListToolBarComponents';
import PrimaryButton from '../../../common/buttons/PrimaryButton';

const ListToolBar = ({ buttonText, onClick }) => {
  return (
    <>
      <Wrapper>
        <InputWrapper>
          <SearchField />
        </InputWrapper>
        <InputWrapper>
          <Select
            style={{ backgroundColor: '#f2f2f3' }}
            variant="filled"
            IconComponent={ArrowDropDownIcon}
            labelId="label"
            id="select"
            value={PROJECT_TYPES[0].value}>
            {PROJECT_TYPES.map(({ id, label, value }) => (
              <MenuItem key={id} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </InputWrapper>
        <InputWrapper>
          <Select
            style={{ backgroundColor: '#f2f2f3' }}
            variant="filled"
            IconComponent={ArrowDropDownIcon}
            labelId="label"
            id="select"
            value={PROJECT_TYPES[0].value}>
            {PROJECT_TYPES.map(({ id, label, value }) => (
              <MenuItem key={id} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </InputWrapper>
        <PrimaryButton onClick={onClick}>{buttonText}</PrimaryButton>
      </Wrapper>
    </>
  );
};

ListToolBar.propTypes = {
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ListToolBar.defaultProps = {
  onClick: () => {},
};

export default ListToolBar;
