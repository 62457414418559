import React, { useState, useRef } from 'react';
import ListToolBar from '../../components/screen-components/documents/list/ListToolBar';
import BasicTable from '../../components/common/tables/basic/BasicTable';
import { headCells, rows } from '../../mocks/documentsTableMock';
import EditDocumentPopup from '../../components/screen-components/documents/edit/EditDocumentPopup';
import AddDocumentPopup from '../../components/screen-components/documents/add/AddDocumentPopup';

const DocumentsScreen = () => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const editPopupRef = useRef();
  const addPopupRef = useRef();

  const handleEditPopupOpen = (id) => {
    setSelectedDocument(id);
    editPopupRef.current.open();
  };

  const handleAddPopupOpen = () => {
    addPopupRef.current.open();
  };

  return (
    <>
      <ListToolBar action={handleAddPopupOpen} />
      <BasicTable
        headCells={headCells}
        rows={rows}
        onRowClick={({ id }) => handleEditPopupOpen(id)}
      />
      <EditDocumentPopup ref={editPopupRef} id={selectedDocument} />
      <AddDocumentPopup ref={addPopupRef} id={selectedDocument} />
    </>
  );
};

export default DocumentsScreen;
