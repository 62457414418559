import React, { useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Divider,
  TextField,
} from '@material-ui/core';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import ColumnWrapper from '../../../common/wrappers/ColumnWrapper';

const Container = styled(ColumnWrapper)`
  padding: 25px;
  min-width: 400px;
  align-items: flex-start;
`;

const AddDocumentPopup = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    close() {
      setOpen(false);
    },
    open() {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent>
        <Container>
          <Typography style={{ fontWeight: 'bold', margin: '0 0 20px' }} variant="body1">
            Dodawanie pliku
          </Typography>
          <TextField variant="filled" label="Nazwa" />
          <Typography style={{ margin: '20px 0 5px' }} variant="body2">
            Plik
          </Typography>
          <PrimaryButton size="small">Dodaj plik</PrimaryButton>
        </Container>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: 20 }}>
        <PrimaryButton onClick={handleClose} color="secondary">
          Anuluj
        </PrimaryButton>
        <PrimaryButton onClick={handleClose}>Zapisz</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

export default AddDocumentPopup;
