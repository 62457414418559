import React from 'react';
import styled from 'styled-components';
import routerHistory from '../../../../../settings/routerHistory';
import BackButton from '../../../../common/buttons/back-button/BackButton';
import RowWrapper from '../../../../common/wrappers/RowWrapper';

const Wrapper = styled(RowWrapper)`
  justify-content: space-between;
`;

const SlotToolBar = () => {
  return (
    <>
      <Wrapper>
        <BackButton text="Wróć" onClick={() => routerHistory.push('/calendar')} />
      </Wrapper>
    </>
  );
};

export default SlotToolBar;
