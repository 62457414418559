import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily:
      'Inter',
  },
  palette: {
    primary: {
      light: '#7b848c',
      main: '#5b6670',
      dark: '#3f474e',
      contrastText: '#000',
    },
    secondary: {
      main: '#78909c',
      contrastText: '#000',
    },
  },
});

export default theme;