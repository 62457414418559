import React from 'react';
import { Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import styled from 'styled-components';
import RowWrapper from '../../../../common/wrappers/RowWrapper';
import BackButton from '../../../../common/buttons/back-button/BackButton';
import ColumnWrapper from '../../../../common/wrappers/ColumnWrapper';
import ActiveStatus from '../../list/ActiveStatus';
import routerHistory from '../../../../../settings/routerHistory';

const Wrapper = styled(RowWrapper)`
  justify-content: space-between;
`;

const ServiceToolBar = () => {
  const { id } = useParams();

  return (
    <>
      <Wrapper>
        <RowWrapper>
          <BackButton
            text="Wróć do projektu"
            onClick={() => routerHistory.push(`/projects/${id}`)}
          />
          <ColumnWrapper>
            <Typography>Projekt: {id}</Typography>
            <ActiveStatus />
          </ColumnWrapper>
        </RowWrapper>
      </Wrapper>
    </>
  );
};

export default ServiceToolBar;
