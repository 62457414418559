const currency = [{
  label: 'Kurs Euro',
}, {
  label: 'Z dnia'
}];

const commissionTypes = [{
  label: 'Projektowa',
  fields: [{
    unitType: 'Netto',
  }, {
    unitType: 'Brutto'
  }]
}, {
  label: 'Audytowa',
  fields: [{
    unitType: 'Netto',
  }, {
    unitType: 'Brutto'
  }]
}, {
  label: 'Inne',
  fields: [{
    unitType: 'Netto',
  }, {
    unitType: 'Brutto'
  }]
}];

const persons = [{
  label: 'Dla',
}, {
  label: 'Osoba',
}]

const others = [{
  label: 'Mail do klienta/KR',
  type: 'multiple'
}, {
  label: 'Rozliczona',
  type: 'multiple'
}, {
  label: 'Notatka'
}]

export { currency, commissionTypes, persons, others };