import React from 'react';
import { Checkbox as Box } from '@material-ui/core';

const Checkbox = () => {
  return (
    <>
      <Box />
    </>
  );
};

export default Checkbox;
