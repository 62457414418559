import React from 'react';
import { Typography } from '@material-ui/core';
import ColumnWrapper from '../../../components/common/wrappers/ColumnWrapper';
import RowWrapper from '../../../components/common/wrappers/RowWrapper';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import { headCells, rows } from '../../../mocks/marketingImportListTableMock';
import BasicTable from '../../../components/common/tables/basic/BasicTable';

const ImportContactListScreen = () => (
  <>
    <ColumnWrapper>
      <RowWrapper style={{ justifyContent: 'space-between', marginBottom: 20 }}>
        <Typography variant="body2">
          Odnaleźliśmy następujące kontakty. Potwierdź import lub wyiberz kontakty do ignorowania.
        </Typography>
        <PrimaryButton>Importuj</PrimaryButton>
      </RowWrapper>
      <BasicTable headCells={headCells} rows={rows} />
    </ColumnWrapper>
  </>
);

export default ImportContactListScreen;
