import React from 'react';
import routerHistory from '../../settings/routerHistory';
import UserDetails from '../../components/screen-components/administration/user-details/UserDetails';
import BackButton from '../../components/common/buttons/back-button/BackButton';

const AdministrationUserDetailsScreen = () => (
  <>
    <BackButton text="Wróć" onClick={() => routerHistory.push('/administration/users')} />
    <UserDetails />
  </>
);

export default AdministrationUserDetailsScreen;
