import uniqid from 'uniqid'
import React from 'react'
import EmailIcon from '@material-ui/icons/Email';

function createData(name, data, city, date, contact) {
  return { name, data, city, date, contact, id: uniqid() };
}

const returnActionBtn = () => <EmailIcon style={{ cursor: 'pointer' }} />

const rows = [
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn()),
  createData('Imię Nazwisko', 'Dane', 'Bydgoszcz (KUJ-POM)', '21.02.2020', returnActionBtn()),
];

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'nazwa' },
  { id: 'data', numeric: false, disablePadding: false, label: 'dane' },
  { id: 'city', numeric: false, disablePadding: false, label: 'miasto' },
  { id: 'date', numeric: false, disablePadding: false, label: 'data' },
  { id: 'contact', numeric: false, disablePadding: false, label: 'kontakt' },
];

export { rows, headCells };