const fields = [
  { label: 'Kod' },
  { label: 'Miejscowość' },
  { label: 'Województwo' },
  { label: 'Adres' },
  { label: 'Telefon 1' },
  { label: 'Telefon 2' },
  { label: 'Fax' },
  { label: 'Strona www' },

];

export default fields;