import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Avatar,
  Divider,
  TextField,
} from '@material-ui/core';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import RowWrapper from '../../../common/wrappers/RowWrapper';
import ColumnWrapper from '../../../common/wrappers/ColumnWrapper';

const ItemWrapper = styled(RowWrapper)`
  margin: 10px 0;
`;

const Container = styled(RowWrapper)`
  padding: 25px;
  min-width: 400px;
  align-items: flex-start;
`;

const EditDocumentPopup = forwardRef(({ id }, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    close() {
      setOpen(false);
    },
    open() {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent>
        <Container>
          <ColumnWrapper style={{ marginRight: 20 }}>
            <Typography style={{ fontWeight: 'bold' }} variant="body1">
              Nazwa pliku
            </Typography>
            <Typography variant="body2">Typ: pdf</Typography>
            <ItemWrapper>
              <Avatar
                alt="user"
                src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
              />
              <Typography style={{ margin: '0 0 0 5px' }} variant="body2">
                Piotr
              </Typography>
            </ItemWrapper>
            <ItemWrapper>
              <Typography variant="body2">Dodano: 23.03.2020, 13:30</Typography>
            </ItemWrapper>
            <PrimaryButton size="small">Pobierz</PrimaryButton>
          </ColumnWrapper>
          <ColumnWrapper style={{ marginLeft: 20 }}>
            <Typography style={{ fontWeight: 'bold' }} variant="body1">
              Edycja pliku {id}
            </Typography>
            <ItemWrapper>
              <TextField variant="filled" label="Nazwa" />
            </ItemWrapper>
          </ColumnWrapper>
        </Container>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: 20 }}>
        <PrimaryButton onClick={handleClose} color="secondary">
          Anuluj
        </PrimaryButton>
        <PrimaryButton onClick={handleClose}>Zapisz</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

EditDocumentPopup.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

EditDocumentPopup.defaultProps = {
  id: '',
};

export default EditDocumentPopup;
