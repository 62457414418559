const authReducer = (defaultState = { isAuthenticated: false }, action) => {
  switch (action.type) {
    case 'SET_IS_AUTHENTICATED':
      return {
        isAuthenticated: action.payload.isAuthenticated,
      };
    default:
      return defaultState;
  }
};

export default authReducer;
