import React from 'react';
import {
  InputWrapper,
  InputLabel,
  StrictColWrapper,
} from '../../screen-components/shared/FormComponents';
import returnSpecificField from './returnSpecificField';

const DynamicForm = ({ fields, topChildren, bottomChildren }) => {
  return (
    <StrictColWrapper fullWidth>
      {topChildren}
      {fields.map((item, index) => (
        <InputWrapper key={`${item.label}_${index}`}>
          {item.label ? (
            <InputLabel style={{ fontWeight: 'bold', minWidth: 140 }} variant="subtitle2">
              {item.label}
            </InputLabel>
          ) : null}
          {returnSpecificField(item.type, item.options)}
        </InputWrapper>
      ))}
      {bottomChildren}
    </StrictColWrapper>
  );
};

export default DynamicForm;
