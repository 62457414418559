import React from 'react';
import ColumnWrapper from '../../components/common/wrappers/ColumnWrapper';
import DetailsToolBar from '../../components/screen-components/offers/details/DetailsToolBar';
import OfferDetailsData from '../../components/screen-components/offers/details/OfferDetailsData';

const OffersDetailsScreen = () => {
  return (
    <>
      <ColumnWrapper>
        <DetailsToolBar />
        <OfferDetailsData />
      </ColumnWrapper>
    </>
  );
};

export default OffersDetailsScreen;
