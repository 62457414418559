import uniqid from 'uniqid'

function createData(name, unit, price, currency, discount, buyPrice) {
  return { name, unit, price, currency, discount, buyPrice, id: uniqid() };
}

const rows = [
  createData('nazwa', '11', '200 PLN', 'PLN', '20%', '220 PLN'),
  createData('nazwa', '11', '200 PLN', 'PLN', '20%', '220 PLN'),
  createData('nazwa', '11', '200 PLN', 'PLN', '20%', '220 PLN'),
  createData('nazwa', '11', '200 PLN', 'PLN', '20%', '220 PLN'),
  createData('nazwa', '11', '200 PLN', 'PLN', '20%', '220 PLN'),
  createData('nazwa', '11', '200 PLN', 'PLN', '20%', '220 PLN'),
  createData('nazwa', '11', '200 PLN', 'PLN', '20%', '220 PLN'),
  createData('nazwa', '11', '200 PLN', 'PLN', '20%', '220 PLN'),
  createData('nazwa', '11', '200 PLN', 'PLN', '20%', '220 PLN'),
  createData('nazwa', '11', '200 PLN', 'PLN', '20%', '220 PLN'),
  createData('nazwa', '11', '200 PLN', 'PLN', '20%', '220 PLN'),
  createData('nazwa', '11', '200 PLN', 'PLN', '20%', '220 PLN'),
];

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'typ' },
  { id: 'unit', numeric: false, disablePadding: false, label: 'firma' },
  { id: 'price', numeric: false, disablePadding: false, label: 'miasto' },
  { id: 'currency', numeric: false, disablePadding: false, label: 'numer' },
  { id: 'discount', numeric: false, disablePadding: false, label: 'dla' },
  { id: 'buyPrice', numeric: false, disablePadding: false, label: 'data' },
];

export { rows, headCells };