import React from 'react';
import { Typography } from '@material-ui/core';
import ColumnWrapper from '../../components/common/wrappers/ColumnWrapper';
import ActionContainer from '../../components/unique/action-container/ActionContainer';

const ReportsScreen = () => (
  <>
    <ColumnWrapper>
      <Typography variant="body2">Wybierz raport</Typography>
      <ActionContainer label="Tabela zbiorcza prowizji" />
      <ActionContainer label="Tabela zbiorcza płatności" />
    </ColumnWrapper>
  </>
);

export default ReportsScreen;
