const fields = [
  {
    label: 'Zapotrzebowanie', type: 'checkbox',
  },
  {
    label: 'Wysłany', type: 'multipleFields', options: {
      fields: [
        { type: 'checkbox' },
        { label: 'Ilość' },
        { label: 'Data wysłania' },
      ]
    },
  },
  {
    label: 'Schemat technologiczny', type: 'multipleFields', options: {
      fields: [{
        type: 'button', options: { text: 'Plik PDF' },
      }, { type: 'checkbox' }]
    },
  },
  {
    label: 'Zestawienie armatury', type: 'multipleFields', options: {
      fields: [{
        type: 'checkbox',
      }, { label: 'Data' }]
    },
  },
  {
    label: 'Notatki', type: 'multilineInput'
  },
  {
    label: 'Wysłane do zaprojektowania', type: 'multipleFields', options: {
      fields: [{
        type: 'checkbox',
      }, { label: 'Data' }]
    },
  },
  {
    label: 'Projekt szafy', type: 'multipleFields', options: {
      fields: [{
        type: 'button',
        options: {
          text: 'Plik PDF'
        }
      }, {
        type: 'checkbox',
      }, { label: 'Data' }]
    },
  },
];

export default fields;