import styled from 'styled-components';

const NoteLabel = styled.div`
  background: ${(props) => props.color};
  color: #fff;
  border-radius: 6px;
  padding: 4px 8px;
  margin: 0 5px;
`;

export default NoteLabel;