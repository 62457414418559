import React from 'react';
import { TextField, Avatar, Typography } from '@material-ui/core';
import RowWrapper from '../../../common/wrappers/RowWrapper';
import Select from '../../../common/dynamic-forms/form-components/Select';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import ColumnWrapper from '../../../common/wrappers/ColumnWrapper';
import ExpansionContainer from '../../../common/containers/ExpansionContainer';

const verticalInputMarign = { margin: '10px 0' };
const labelMarign = { marginBottom: '5px' };
const HorizontalMarign = { margin: '0 50px' };

const UserDetails = () => {
  return (
    <>
      <ExpansionContainer title="Dane użytkownika">
        <ColumnWrapper style={HorizontalMarign}>
          <RowWrapper style={verticalInputMarign}>
            <Avatar
              style={{ marginRight: 10 }}
              alt="Adam"
              src="https://images.unsplash.com/photo-1586297098710-0382a496c814?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
            />
            <Typography variant="body2">Adam</Typography>
          </RowWrapper>
          <ColumnWrapper style={verticalInputMarign}>
            <Typography style={labelMarign} variant="body2">
              3 powiązane projekty
            </Typography>
            <PrimaryButton color="secondary" icon={<AssignmentIcon />}>
              Projekty
            </PrimaryButton>
          </ColumnWrapper>
          <ColumnWrapper style={verticalInputMarign}>
            <Typography style={labelMarign} variant="body2">
              4 utworzone oferty
            </Typography>
            <PrimaryButton color="secondary" icon={<FindInPageIcon />}>
              Oferty
            </PrimaryButton>
          </ColumnWrapper>
          <ColumnWrapper style={verticalInputMarign}>
            <Typography style={labelMarign} variant="body2">
              16 bieżących zadań
            </Typography>
            <PrimaryButton color="secondary" icon={<DoneAllIcon />}>
              Zadania
            </PrimaryButton>
          </ColumnWrapper>
        </ColumnWrapper>
        <ColumnWrapper style={HorizontalMarign}>
          <TextField style={verticalInputMarign} variant="filled" placeholder="Nazwa" />
          <TextField style={verticalInputMarign} variant="filled" placeholder="E-mail" />
          <TextField style={verticalInputMarign} variant="filled" placeholder="Telefon" />
          <Select />
          <PrimaryButton style={{ marginTop: 20 }}>Zapisz</PrimaryButton>
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default UserDetails;
