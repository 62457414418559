import React from 'react';
import FloatingSaveBar from '../../components/screen-components/shared/FloatingSaveBar';
import SlotToolBar from '../../components/screen-components/calendar/slot/tool-bar/SlotToolBar';
import WorkersList from '../../components/screen-components/calendar/slot/workers/WorkersList';
import EventForm from '../../components/screen-components/calendar/slot/event-form/EventForm';
import ExpansionContainer from '../../components/common/containers/ExpansionContainer';
import Notes from '../../components/screen-components/calendar/slot/notes/Notes';

const SlotScreen = ({ onSave }) => {
  return (
    <>
      <SlotToolBar />
      <ExpansionContainer title="Formularz">
        <WorkersList />
        <EventForm />
      </ExpansionContainer>
      <ExpansionContainer title="Notatki">
        <Notes />
      </ExpansionContainer>
      <FloatingSaveBar onClick={onSave} />
    </>
  );
};

export default SlotScreen;
