import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import returnSpecificField from '../returnSpecificField';

const MultipleFields = ({ fields }) => {
  return (
    <>
      {fields.map((item, i) => (
        <Fragment key={`item_${i}`}>
          {item.label ? (
            <Typography style={{ margin: '0 10px' }} variant="subtitle2">
              {item.label}
            </Typography>
          ) : null}
          {returnSpecificField(item.type, item.options)}
        </Fragment>
      ))}
    </>
  );
};

export default MultipleFields;
